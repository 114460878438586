import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CreateUserModal from "./modal_add_data/CreateUserModal";
import * as app_consts from "../../../../../constants/react_app_consts";
import AddProductModal from "./modal_add_data/AddProductModal";
import AddProductTypeModal from "./modal_add_data/AddProductTypeModal";
import AddUnitMeasurementModal from "./modal_add_data/AddUnitMeasurementModal";
import AddUserPropertyModal from "./modal_add_data/AddUserPropertyModal";
import AddCompanyRepresentativeModal from "./modal_add_data/AddCompanyRepresentativeModal";
import AddCompRepRoleModal from "./modal_add_data/AddCompRepRoleModal";
import AddWarehouseStorage from "./modal_add_data/AddWarehouseStorage";
import AddWarehouseStorageType from "./modal_add_data/AddWarehouseStorageType";
import AddDeliveryNoteProductModal from "./modal_add_data/AddDeliveryNoteProductModal";
import AddEmployeeModal from "./modal_add_data/AddEmployeeModal";
import AddGoodsReceivedProductsModal from "./modal_add_data/AddGoodsReceivedProductsModal";
import AddLoadingOrderProduct from "./modal_add_data/AddLoadingOrderProduct";
import AddEmployeeToBranch from "./modal_add_data/AddEmployeeToBranch";

const ModalAdd = ({
  openModal,
  setOpenModal,
  type,
  modalTitle,
  modalDescription,
  succFunc,
  size,
  accountId,
  openOtherModalFunc,
}) => {
  const [modal, setModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    setModal(openModal);
  }, [openModal]);

  const toggleModal = () => {
    setOpenModal(!modal);
  };

  return (
    <Modal
      isOpen={modal}
      // toggle={!modalLoading ? toggleModal : ""}
      className="modal-dialog-centered"
      size={size ? size : "lg"}
    >
      <ModalHeader toggle={!modalLoading ? toggleModal : ""}>{modalTitle}</ModalHeader>
      <ModalBody>
        {
          // Users
          type === app_consts.USER_LIST_TYPE ? (
            <CreateUserModal successFunction={succFunc} setModalLoading={setModalLoading} />
          ) : // Products
            type === app_consts.PRODUCT_LIST ? (
              <AddProductModal successFunction={succFunc} openModal={modal} setModalLoading={setModalLoading} />
            ) : // Product Types
              type === app_consts.PRODUCT_TYPE_LIST ? (
                <AddProductTypeModal successFunction={succFunc} openModal={modal} setModalLoading={setModalLoading} />
              ) : // Unit Measurement
                type === app_consts.UNIT_MEASUREMENT_LIST ? (
                  <AddUnitMeasurementModal successFunction={succFunc} openModal={modal} setModalLoading={setModalLoading} />
                ) : // User Porperty
                  type === app_consts.ONBOARD_USER_PROPERTY ? (
                    <AddUserPropertyModal
                      accountId={accountId}
                      successFunction={succFunc}
                      openModal={modal}
                      setModalLoading={setModalLoading}
                    />
                  ) : // Company Representative
                    type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE ? (
                      <AddCompanyRepresentativeModal
                        accountId={accountId}
                        successFunction={succFunc}
                        openModal={modal}
                        setModalLoading={setModalLoading}
                        openOtherModalFunc={openOtherModalFunc}
                      />
                    ) : // Company Representative Role
                      type === app_consts.ONBOARD_COMPANY_REPRESENTATIVE_ROLE ? (
                        <AddCompRepRoleModal
                          accountId={accountId}
                          successFunction={succFunc}
                          openModal={modal}
                          setModalLoading={setModalLoading}
                          openOtherModalFunc={openOtherModalFunc}
                        />
                      ) : // Add Warehouse Storage
                        type === app_consts.ADD_WAREHOUSE_STORAGE ? (
                          <AddWarehouseStorage
                            accountId={accountId}
                            successFunction={succFunc}
                            openModal={modal}
                            setModalLoading={setModalLoading}
                            openOtherModalFunc={openOtherModalFunc}
                          />
                        ) : // Add Warehouse Storage Type
                          type === app_consts.ADD_WAREHOUSE_STORAGE_TYPE ? (
                            <AddWarehouseStorageType
                              successFunction={succFunc}
                              openModal={modal}
                              setModalLoading={setModalLoading}
                              openOtherModalFunc={openOtherModalFunc}
                            />
                          ) : // Delivery Note Product
                            type === app_consts.ADD_PRODUCTS_TO_DELIVERY_NOTE_TICKET ? (
                              <AddDeliveryNoteProductModal
                                successFunction={succFunc}
                                openModal={modal}
                                setModalLoading={setModalLoading}
                                openOtherModalFunc={openOtherModalFunc}
                                page_data={accountId}
                              />
                            ) : // Add Employee
                              type === app_consts.ADD_EMPLOYEE ? (
                                <AddEmployeeModal
                                  successFunction={succFunc}
                                  openModal={modal}
                                  setModalLoading={setModalLoading}
                                  openOtherModalFunc={openOtherModalFunc}
                                  page_data={accountId}
                                />
                              ) : // Add Goods REceived Product
                                type === app_consts.ADD_PRODUCTS_TO_GOODS_RECEIVED_NOTE_TICKET ? (
                                  <AddGoodsReceivedProductsModal
                                    successFunction={succFunc}
                                    openModal={modal}
                                    setModalLoading={setModalLoading}
                                    openOtherModalFunc={openOtherModalFunc}
                                    page_data={accountId}
                                  />
                                ) : // Add Loading Order Product
                                  type === app_consts.ADD_LOADING_ORDER_PRODUCT_TABLE ? (
                                    <AddLoadingOrderProduct
                                      successFunction={succFunc}
                                      openModal={modal}
                                      setModalLoading={setModalLoading}
                                      openOtherModalFunc={openOtherModalFunc}
                                      page_data={accountId}
                                    />
                                  ) : // Add Employee to Branch
                                    type === app_consts.ADD_EMPLOYEE_TO_BRANCH ? (
                                      <AddEmployeeToBranch
                                        successFunction={succFunc}
                                        openModal={modal}
                                        setModalLoading={setModalLoading}
                                        openOtherModalFunc={openOtherModalFunc}
                                        page_data={accountId}
                                      />
                                    ) : (
                                      ""
                                    )
        }
      </ModalBody>
    </Modal>
  );
};

export default ModalAdd;