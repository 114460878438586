import React, { useEffect, useState } from "react";
import Head from "../../../../layout/head/Head";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as app_consts from "../../../../constants/react_app_consts";
import Content from "../../../../layout/content/Content";
import TablePageHeading from "../../components/page_heading/TablePageHeading";
import { Block } from "../../../../components/Component";
import TableList from "../../components/table/TableList";
import { employee_dashboard_actions_helper } from "../../../../actions/actions_helper";
import {
  fail_product_list,
  loading_product_list,
  reset_product_list,
  success_product_list,
} from "../../../../reducers/dashboard_reducers/products/product_list_Slice";
import {
  AMEND_PRODUCT_STATUS_ACCESS_DENIED_MSG,
  AMEND_PRODUCT_STATUS_ERR_MSG,
  AMEND_PRODUCT_STATUS_SUCCESS_MSG,
  RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_PRODUCTS_LIST_ERR_MSG,
  RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG,
} from "../../../../constants/success_error_messages_consts";
import { useSelector } from "react-redux";
import { AMEND_PRODUCTS_STATUS, RETRIEVE_PRODUCTS_LIST } from "../../../../api/api_data";
import { messageToast } from "../../../../utils/Utils";
import ModalDetails from "../../components/modal/modal_details/ModalDetails";
import ModalConfirmCancel from "../../components/modal/modal_confirm_cancel/ModalConfirmCancel";
import {
  fail_amend_product_status,
  loading_amend_product_status,
  reset_amend_product_status,
  success_amend_product_status,
} from "../../../../reducers/dashboard_reducers/products/amend_product_status_Slice";
import ModalAdd from "../../components/modal/modal_add/ModalAdd";
import { findModuleAction } from "../../../../utils/functionalUtils";
import { Spinner } from "reactstrap";
import { BiErrorCircle } from "react-icons/bi";

const initial_actions_data = { add_product: false, amend_product_status: false };
const type = app_consts.PRODUCT_LIST;
const initial_amend_user_status = { product_id: "", account_status_id: "" };

const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page_data, setPageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [componentData, setComponentData] = useState(initial_actions_data);
  const [openModalDetails, setOpendModalDetails] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [detail, setDetail] = useState([]);
  const [acc_status, setAccStatus] = useState("");
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [amendStatusFormData, setAmendStatusFormData] = useState(initial_amend_user_status);
  const [errAmendAccStat, setErrAmendAccStat] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [account_actions, setAccountActions] = useState([]);
  const [error, setError] = useState("");

  //Retrieve Product List on Page Load
  useEffect(() => {
    retrieve_product_list();
    if (localStorage.getItem("log_cred"))
    {
      setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
    }
  }, []);

  // Listen to Changes In Account Actions
  useEffect(() => {
    if (account_actions)
    {
      let actions_data = initial_actions_data;
      account_actions?.map((module) => {
        if (module.module_name === app_consts.SYSTEM_MANAGEMENT) 
        {
          // Actions Data
          if (findModuleAction(app_consts.ADD_PRODUCT, module))
          {
            actions_data.add_product = true;
          }
          if (findModuleAction(app_consts.AMEND_PRODUCT_STATUS, module))
          {
            actions_data.amend_product_status = true;
          }
        }
      });
      setComponentData(actions_data);
    }
  }, [account_actions]);


  // Retrieve Product List
  const retrieve_product_list = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_product_list,
        success_product_list,
        fail_product_list,
        RETRIEVE_PRODUCTS_LIST_ERR_MSG,
        RETRIEVE_PRODUCTS_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_PRODUCTS_LIST,
        null,
        RETRIEVE_PRODUCTS_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  //Listen to State of Product List
  const product_list = useSelector((state) => state.product_list.product_list);

  useEffect(() => {
    if (product_list.status !== app_consts.IDLE)
    {
      if (product_list.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (product_list.status === app_consts.FAIL)
        {
          messageToast(product_list.message, app_consts.FAIL);
        } else if (product_list.status === app_consts.SUCCESS)
        {
          setPageData(product_list.data);
        }
        dispatch(reset_product_list());
      }
    }
  }, [product_list]);

  // RefreshList Function
  const refreshList = () => {
    setError("");
    retrieve_product_list();
    setLoading(true);
    setPageData([]);
  };

  // UseEffect for Specific Data
  useEffect(() => {
    if (selectedData)
    {
      setDetail(filterSpecificProduct(selectedData));
    }
  }, [selectedData]);

  // Filter Specific Product
  const filterSpecificProduct = (selectedData) => {
    const filtereredProduct = page_data?.filter((data) => data.product_id === selectedData);
    return filtereredProduct[0];
  };

  // Function to Amend Product Status
  const amend_Account_Status_modal = (product_id, account_status_id, account_status) => {
    setAccStatus(account_status);
    setOpenConfirmCancelModal(true);
    setSelectedData(product_id);
    setAmendStatusFormData({
      ...amendStatusFormData,
      ["product_id"]: product_id,
      ["account_status_id"]: account_status_id,
    });
  };

  // Function to Amend Branch Location Account Status
  const amend_Account_Status = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_amend_product_status,
        success_amend_product_status,
        fail_amend_product_status,
        AMEND_PRODUCT_STATUS_ERR_MSG,
        AMEND_PRODUCT_STATUS_SUCCESS_MSG,
        app_consts.POST,
        AMEND_PRODUCTS_STATUS,
        amendStatusFormData,
        AMEND_PRODUCT_STATUS_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Amend Product Status Reducer
  const amend_product_status = useSelector((state) => state.amend_product_status.amend_product_status);
  // UseEffect for Amend Product Status
  useEffect(() => {
    if (amend_product_status.status !== app_consts.IDLE)
    {
      if (amend_product_status.status !== app_consts.LOADING)
      {
        if (amend_product_status.status === app_consts.FAIL)
        {
          setErrAmendAccStat(amend_product_status.message);
        } else if (amend_product_status.status === app_consts.SUCCESS)
        {
          messageToast(
            `${detail && `${detail.product_name}'s `} Status has been Successfully Amended.`,
            app_consts.SUCCESS
          );
          setAccStatus("");
          setDetail([]);
          refreshList();
          setSelectedData("");
          setErrAmendAccStat("");
          setOpenConfirmCancelModal(false);
        }
        dispatch(reset_amend_product_status());
      }
    }
  }, [amend_product_status]);

  // Open Modal to Add to LIst
  const openAddList = () => {
    setOpenAddModal(true);
  };

  // Success Function for Adding Product Via Modal
  const successAddFunc = () => {
    setOpenAddModal(false);
    refreshList();
  };

  return (
    <>
      <Head title="Product List" />
      <Content>
        <TablePageHeading
          title="Product List"
          description={`Total Products: ${page_data.length}`}
          refresh={!loading}
          refreshFunc={refreshList}
          csv_export={!loading && !error}
          openAddList={openAddList}
          add_to_list={loading || error ? "" : componentData.add_product}
          //   page_type={type}
          pageData={page_data}
        />
        <Block>
          {
            loading ?
              <>
                <div className='w-full p-5 d-flex justify-center items-center'>
                  <Spinner />
                </div>
              </>
              :
              error && error.length > 0 ?
                <>
                  <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                    <BiErrorCircle className="text-danger fs-3" />
                    <p>{error}</p>
                  </div>
                </>
                :
                <>
                  <TableList
                    type={type}
                    data={page_data}
                    rowOptionsData={componentData}
                    amendFunc={amend_Account_Status_modal}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                  />

                  {/* Add Modal */}
                  <ModalAdd
                    openModal={openAddModal}
                    setOpenModal={setOpenAddModal}
                    modalTitle="Add Product"
                    modalDescription="Please Complete this form to Add a Product"
                    type={type}
                    size="lg"
                    succFunc={successAddFunc}
                  />
                  {/* Yes or No Modal */}
                  <ModalConfirmCancel
                    openModal={openConfirmCancelModal}
                    setOpenModal={setOpenConfirmCancelModal}
                    modalTitle={`${acc_status} ${detail && `${detail.product_name}`}?`}
                    modalDescription={`Are you sure that you want to ${acc_status} ${detail.product_name}?`}
                    buttonConfirm="Confirm"
                    buttonCancel="Cancel"
                    succFunc={amend_Account_Status}
                    errAmend={errAmendAccStat}
                    setErrAmend={setErrAmendAccStat}
                  />
                  {/* Modal For Viewing Product Details */}
                  <ModalDetails
                    modalOpen={openModalDetails}
                    setModalOpen={setOpendModalDetails}
                    modalTitle={`${detail && `${detail.product_name}'s Details`}`}
                    modalType={app_consts.PRODUCT_LIST}
                    modalData={detail}
                    size="lg"
                  />
                </>
          }
        </Block>
      </Content>
    </>
  );
};

export default ProductList;
