import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import TablePageHeading from '../components/page_heading/TablePageHeading';
import Content from '../../../layout/content/Content';
import CardTabs from '../components/cards/CardTabs';
import TableList from '../components/table/TableList';
import { Block, Col, Row } from '../../../components/Component';
import { RETRIEVE_WAREHOUSE_INVENTORY_LIST_API } from '../../../api/api_data';
import { fail_retrieve_inventory_list, loading_retrieve_inventory_list, reset_retrieve_inventory_list, success_retrieve_inventory_list } from '../../../reducers/dashboard_reducers/warehouse/retrieve_inventory_list_Slice';
import { RETRIEVE_WAREHOUSE_INVNENTORY_LIST_ACCESS_DENIED_MSG, RETRIEVE_WAREHOUSE_INVNENTORY_LIST_ERR_MSG, RETRIEVE_WAREHOUSE_INVNENTORY_LIST_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { useDispatch } from 'react-redux';
import { messageToast } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import * as app_consts from "../../../constants/react_app_consts";
import ModalDetails from '../components/modal/modal_details/ModalDetails';
import { BiErrorCircle } from 'react-icons/bi';
import { Spinner } from 'reactstrap';
import DataCard from '../../../components/partials/default/DataCard';
import { formatScientificNotation } from '../../../utils/functionalUtils';
import InventoryProcessingStats from './warehouse_data/InventoryProcessingStats';

const type = app_consts.WAREHOUSE_INVENTORY_LIST;

const WarehouseInventoryList = () => {

    const dispatch = useDispatch();
    const [physical_receipt_data, setPhysicalReceiptData] = useState([]);
    const [virtual_receipt_data, setVirtualReceiptData] = useState([]);
    const [page_data, setPageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [componentData, setComponentData] = useState([]);
    const [openModalDetails, setOpendModalDetails] = useState(false);
    const [selectedData, setSelectedData] = useState("");
    const [detail, setDetail] = useState([]);
    const [activeCardTab, setActiveCardTab] = useState("All");
    const [dataFetched, setDataFetched] = useState(false);

    const [current_inventory, setCurrentInventory] = useState([]);
    const [inStockInventory, setInstockInventory] = useState([]);
    const [zeroStockInventory, setZeroStockInventory] = useState([]);

    // UseEffect to Run When Page opens RETRIEVE_WAREHOUSE_INVENTORY_LIST_API
    useEffect(() => {
        retrieve_inventory_list_func();
    }, []);

    // Retrieve Users
    const retrieve_inventory_list_func = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_retrieve_inventory_list,
                success_retrieve_inventory_list,
                fail_retrieve_inventory_list,
                RETRIEVE_WAREHOUSE_INVNENTORY_LIST_ERR_MSG,
                RETRIEVE_WAREHOUSE_INVNENTORY_LIST_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_WAREHOUSE_INVENTORY_LIST_API,
                null,
                RETRIEVE_WAREHOUSE_INVNENTORY_LIST_ACCESS_DENIED_MSG
            )
        );
    };

    // Refresh List Function
    const refreshList = () => {
        setError("");
        // Retrieve Weigh Bridge Tickets list
        //   retrieve_user_list_func();
        retrieve_inventory_list_func();
        setPageData([]);
    };

    // Listen to Inventory list Reducer
    const retrieve_inventory_list = useSelector((state) => state.retrieve_inventory_list.retrieve_inventory_list);
    // 
    useEffect(() => {
        if (retrieve_inventory_list.status !== app_consts.IDLE)
        {
            if (retrieve_inventory_list.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (retrieve_inventory_list.status === app_consts.FAIL)
                {
                    setError(retrieve_inventory_list.message);
                    messageToast(RETRIEVE_WAREHOUSE_INVNENTORY_LIST_ERR_MSG, app_consts.FAIL);
                } else if (retrieve_inventory_list.status === app_consts.SUCCESS)
                {
                    setPageData(retrieve_inventory_list.data);
                    setCurrentInventory(retrieve_inventory_list?.data?.product_inventory);
                }
                dispatch(reset_retrieve_inventory_list());
            }
        }
    }, [retrieve_inventory_list]);

    // UseEffect on Inventory Listener
    useEffect(() => {
        if (current_inventory?.length > 0)
        {
            const filtered_in_stock = current_inventory?.filter((invent) => {
                return parseFloat(invent?.total_warehouse_store) !== 0;
            });
            const filtered_zero_stock = current_inventory?.filter((invent) => {
                return parseFloat(invent?.total_warehouse_store) === 0;
            });
            setInstockInventory(filtered_in_stock);
            setZeroStockInventory(filtered_zero_stock);
        }
        else
        {
            setInstockInventory([]);
            setZeroStockInventory([]);
        }
    }, [current_inventory]);

    // Select User Actions Parse Components Accordingly

    // Retrieve Module Actions Data
    const action_roles = useSelector((state) => state.auth_routes.auth_routes);
    // UseEffect to Place Page Data
    useEffect(() => {
        if (action_roles.status === app_consts.SUCCESS)
        {
            if (action_roles.data.onboarding || action_roles.data.user_management)
            {
                // Set Global Return Actions Data. Parse Later

            }
        }
    }, [action_roles]);

    // UseEffect for Specific Data
    useEffect(() => {
        if (selectedData)
        {
            setDetail(filterSpecificData(selectedData));
        }
    }, [selectedData]);

    // Filter Specific User
    const filterSpecificData = (selected_user) => {
        const filtereredData = page_data?.product_inventory?.filter((data) => data.product_id === selected_user);
        return filtereredData[0];
    };

    // Card Tabs
    const tabs = [
        {
            tabName: "All"
        },
        {
            tabName: "Available Stock"
        },
        {
            tabName: "Out of Stock"
        }
    ];

    return (
        <>
            <Head title="Warehouse Inventory" />
            <Content>
                {/* Table Page Heading */}
                <TablePageHeading
                    title="Warehouse Inventory"
                    // description={`Total Warehouse Inventory Capacity: ${page_data.length}`}
                    refresh={!loading}
                    refreshFunc={refreshList}
                    // csv_export={!loading && !error}
                    //   add_to_list={
                    //     componentData.create_goods_received_weigh_bridge_ticket ||
                    //     componentData.create_delivery_note_weigh_bridge_ticket
                    //   }
                    //   componentData={componentData}
                    page_type={type}
                    pageData={page_data?.product_inventory}
                //   openAddList={openAddListModal}
                />
                {
                    loading ?
                        <>
                            <div className='p-5 d-flex flex-column align-center justify-center'>
                                <Spinner />
                                <span className='fs-18px mt-2'>Loading Warehouse Inventory Data ...</span>
                            </div>
                        </>
                        :
                        <>
                            {
                                error && error.length > 0 ?
                                    <>
                                        <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                            <BiErrorCircle className="text-danger fs-3" />
                                            <p>{error}</p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Row className="g-gs">
                                            <Col xxl="3" sm="6">
                                                <DataCard
                                                    title="Branch Locations"
                                                    up={true}
                                                    //   chart={<DefaultOrderChart />}
                                                    amount={page_data?.overview_data?.branch_locations}
                                                />
                                            </Col><Col xxl="3" sm="6">
                                                <DataCard
                                                    title="Total Capacity"
                                                    up={true}
                                                    amount={page_data?.overview_data?.total_storage_capcty
                                                        ? formatScientificNotation(`${page_data?.overview_data.total_storage_capcty} MT`)
                                                        : ""}
                                                />
                                            </Col>
                                            <Col xxl="3" sm="6">
                                                <DataCard
                                                    title="Used Capacity"
                                                    up={true}
                                                    amount={page_data?.overview_data?.total_used_capcity
                                                        ? formatScientificNotation(`${page_data.overview_data.total_used_capcity} MT`)
                                                        : ""}
                                                />
                                            </Col>
                                            <Col xxl="3" sm="6">
                                                <DataCard
                                                    title="Available Capacity"
                                                    up={true}
                                                    amount={page_data?.overview_data?.available_capacity
                                                        ? formatScientificNotation(`${page_data.overview_data.available_capacity} MT`)
                                                        : ""}
                                                />
                                            </Col>

                                            <Col xxl="9">
                                                <hr />
                                                <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={dataFetched} />
                                                {/* Table */}

                                                {
                                                    activeCardTab === "All" && (
                                                        <>
                                                            <TableList
                                                                // left_head_select
                                                                type={type}
                                                                data={current_inventory}
                                                                rowOptionsData={componentData}
                                                                // amendFunc={amend_Account_Status_modal}
                                                                setOpendModalDetails={setOpendModalDetails}
                                                                setSelectedData={setSelectedData}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {
                                                    activeCardTab === "Available Stock" && (
                                                        <>
                                                            <TableList
                                                                // left_head_select
                                                                type={type}
                                                                data={inStockInventory}
                                                                rowOptionsData={componentData}
                                                                // amendFunc={amend_Account_Status_modal}
                                                                setOpendModalDetails={setOpendModalDetails}
                                                                setSelectedData={setSelectedData}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {
                                                    activeCardTab === "Out of Stock" && (
                                                        <>
                                                            <TableList
                                                                // left_head_select
                                                                type={type}
                                                                data={zeroStockInventory}
                                                                rowOptionsData={componentData}
                                                                // amendFunc={amend_Account_Status_modal}
                                                                setOpendModalDetails={setOpendModalDetails}
                                                                setSelectedData={setSelectedData}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {/* Modal For Viewing User Data */}
                                                <ModalDetails
                                                    modalOpen={openModalDetails}
                                                    setModalOpen={setOpendModalDetails}
                                                    modalTitle={`${detail && `${detail?.product_name}'s Details`}`}
                                                    modalType={type}
                                                    modalData={detail}
                                                />
                                            </Col>
                                            <Col xxl="3" md="6">
                                                <InventoryProcessingStats data={page_data?.overview_data} />
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </>
                }
            </Content>
        </>
    );
};

export default WarehouseInventoryList;