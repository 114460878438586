import React, { useEffect, useState } from "react";
import {
  BackTo,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  OverlineTitle,
} from "../../../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { export_to_csv_fun } from "../table/table_list_data/table_list_function";
import { ExportToCsv } from "export-to-csv";
import * as app_consts from "../../../../constants/react_app_consts";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { findModuleAction } from "../../../../utils/functionalUtils";

const TablePageHeading = ({
  title,
  description,
  refresh,
  refreshFunc,
  csv_export,
  add_to_list,
  page_type,
  pageData,
  openAddList,
  componentData,
  title_addition,
  backToLink,
  backToText
}) => {
  const [sm, updateSm] = useState(false);
  const [addToList, setAddToList] = useState(add_to_list);
  const [type, setType] = useState(page_type);
  const [data, setData] = useState(pageData);
  const navigate = useNavigate();
  const [compData, setCompData] = useState(componentData);
  const [title_helper, setTitleHelper] = useState(title_addition);

  useEffect(() => {
    setAddToList(add_to_list);
  }, [add_to_list]);

  useEffect(() => {
    setData(pageData);
  }, [pageData]);

  useEffect(() => {
    setType(page_type);
  }, [page_type]);

  useEffect(() => {
    setCompData(componentData);
  }, [componentData]);

  useEffect(() => {
    setTitleHelper(title_helper);
  }, [title_helper]);

  const export_to_csv = async (e) => {
    e.preventDefault();
    const { csv_data, options } = await export_to_csv_fun(data, type);
    // Export CSV
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(await csv_data);
  };

  return (
    <>
      <BlockHead size="sm" className="mt-3">
        <BlockBetween>
          <BlockHeadContent>
            {/* Back Button */}
            {
              backToText && (
                <BackTo link={backToLink} icon="arrow-left">
                  {backToText}
                </BackTo>
              )
            }
            <BlockTitle page tag="h3">
              {title} <strong className="text-primary small">{title_helper ? `/ ${title_helper}` : title_helper}</strong>
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>{description}</p>
            </BlockDes>
            {refresh && (
              <div className="mt-2">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    refreshFunc();
                  }}
                >
                  <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                    <span>Refresh List</span>
                  </Button>
                </Link>
              </div>
            )}
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  {csv_export && (
                    <li>
                      <Button color="light" outline className="btn-white" onClick={export_to_csv}>
                        <Icon name="download-cloud"></Icon>
                        <span>Export to CSV</span>
                      </Button>
                    </li>
                  )}
                  {addToList && (
                    <>
                      {
                        type === app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST ? (
                          <>
                            {(findModuleAction(app_consts.CREATE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET, compData) !== null) && (
                              <>
                                <li className="preview-item">
                                  <UncontrolledDropdown>
                                    <div className="btn-group">
                                      <DropdownToggle className="dropdown-toggle-split" color="primary">
                                        <Icon name="plus"></Icon>
                                      </DropdownToggle>
                                    </div>
                                    <DropdownMenu className="mt-3">
                                      <ul className="link-list-opt">
                                        {(findModuleAction(app_consts.CREATE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET, compData) !== null) && (
                                          <>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="/dashboard/goods_received/create_physical_goods_received_ticket"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  navigate(`/dashboard/goods_received/create_physical_goods_received_ticket`);
                                                }}
                                              >
                                                <span>Create Physical Goods Received Ticket</span>
                                              </DropdownItem>
                                            </li>
                                          </>
                                        )}
                                        {/* {compData.create_delivery_note_weigh_bridge_ticket && (
                                          <>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#links"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  navigate(`/dashboard/goods_received/create_virtual_goods_received_ticket`);
                                                }}
                                              >
                                                <span>Create Virtual Goods Received Ticket</span>
                                              </DropdownItem>
                                            </li>
                                          </>
                                        )} */}
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                              </>
                            )}
                          </>
                        ) :
                          type === app_consts.DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST ? (
                            <>
                              {(compData.create_delivery_note_weigh_bridge_ticket) && (
                                <>
                                  <li className="preview-item">
                                    <UncontrolledDropdown>
                                      <div className="btn-group">
                                        <DropdownToggle className="dropdown-toggle-split" color="primary">
                                          <Icon name="plus"></Icon>
                                        </DropdownToggle>
                                      </div>
                                      <DropdownMenu className="mt-3">
                                        <ul className="link-list-opt">
                                          {compData.create_delivery_note_weigh_bridge_ticket && (
                                            <>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="/dashboard/delivery_notes/create_physical_delivery_note"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    navigate(`/dashboard/delivery_notes/create_physical_delivery_note_ticket`);
                                                  }}
                                                >
                                                  <span>Create Physical Delivery Note Ticket</span>
                                                </DropdownItem>
                                              </li>
                                            </>
                                          )}
                                          {/* {compData.create_delivery_note_weigh_bridge_ticket && (
                                            <>
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#links"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    navigate(`/dashboard/delivery_notes/create_virtual_delivery_note_ticket`);
                                                  }}
                                                >
                                                  <span>Create Virtual Delivery Note Ticket</span>
                                                </DropdownItem>
                                              </li>
                                            </>
                                          )} */}
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </li>
                                </>
                              )}
                            </>
                          )
                            :
                            type === app_consts.LOADING_ORDERS_LIST ? (
                              <>
                                {compData.create_loading_order && (
                                  <>
                                    <li className="preview-item">
                                      <UncontrolledDropdown>
                                        <div className="btn-group">
                                          <DropdownToggle className="dropdown-toggle-split" color="primary">
                                            <Icon name="plus"></Icon>
                                          </DropdownToggle>
                                        </div>
                                        <DropdownMenu className="mt-3">
                                          <ul className="link-list-opt">
                                            {compData.create_loading_order && (
                                              <>
                                                <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="/dashboard/loading_orders/create"
                                                    onClick={(ev) => {
                                                      ev.preventDefault();
                                                      navigate(`/dashboard/loading_orders/create`);
                                                    }}
                                                  >
                                                    <span>Create Loading Order</span>
                                                  </DropdownItem>
                                                </li>
                                                {/* <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="/dashboard/loading_orders/create_bulk"
                                                    onClick={(ev) => {
                                                      ev.preventDefault();
                                                      navigate(`/dashboard/loading_orders/create_bulk`);
                                                    }}
                                                  >
                                                    <span>Create Bulk Loading Order</span>
                                                  </DropdownItem>
                                                </li> */}
                                              </>
                                            )}
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </li>
                                  </>
                                )}
                              </>
                            )
                              : (
                                <li className="nk-block-tools-opt">
                                  <Button onClick={openAddList} color="primary" className="btn-icon">
                                    <Icon name="plus"></Icon>
                                  </Button>
                                </li>
                              )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
    </>
  );
};

export default TablePageHeading;
