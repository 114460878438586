import React, { useEffect, useState } from "react";
import ReadOnlyTableRow from "./ReadOnlyTableRow";
import { Button, DataTableHead, DataTableItem, DataTableRow, Icon } from "../../../../../components/Component";
import moment from "moment";
import * as app_consts from "../../../../../constants/react_app_consts";
import { capitalizeWords, findModuleAction, formatScientificNotation } from "../../../../../utils/functionalUtils";

export const ReadOnlyProperyData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.property_id}>
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.property_name ? (
                      <>
                        {item.property_name.length > 20
                          ? `${item.property_name.substring(0, 20)}...`
                          : item.property_name || "--"}
                      </>
                    ) : (
                      "--"
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.property_physical_address ? (
                      <>
                        {item.property_physical_address.length > 20
                          ? `${item.property_physical_address.substring(0, 20)}...`
                          : item.property_physical_address || "--"}
                      </>
                    ) : (
                      "--"
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.country ? (
                      <>
                        {item.country.length > 20
                          ? `${item.country.substring(0, 20)}...`
                          : item.country || "--"}
                      </>
                    ) : (
                      "--"
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.province ? (
                      <>
                        {item.province.length > 20
                          ? `${item.province.substring(0, 20)}...`
                          : item.province || "--"}
                      </>
                    ) : (
                      "--"
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.district ? (
                      <>
                        {item.district.length > 20
                          ? `${item.district.substring(0, 20)}...`
                          : item.district || "--"}
                      </>
                    ) : (
                      "--"
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.property_id}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const ReadOnlyCompanyRepData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.member_access_id}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item.first_name ? (
                      <>
                        {`${item.first_name} ${item.surname}`.length > 20
                          ? `${`${item.first_name} ${item.surname}`.substring(0, 20)}...`
                          : `${item.first_name} ${item.surname}`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.phone_number ? (
                      <>
                        {item.phone_number.length > 20 ? `${item.phone_number.substring(0, 20)}...` : item.phone_number}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.company_representative_role_name ? (
                      <>
                        {item.company_representative_role_name.length > 20
                          ? `${item.company_representative_role_name.substring(0, 20)}...`
                          : item.company_representative_role_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.member_access_id}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};


export const ReadOnlyWarehouseStorageData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item.warehouse_storage_id}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item.warehouse_storage_reference_number ? (
                      <>
                        {`${item.warehouse_storage_reference_number}`.length > 20
                          ? `${`${item.warehouse_storage_reference_number}`.substring(0, 20)}...`
                          : `${item.warehouse_storage_reference_number}`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.warehouse_storage_capacity ? (
                      <>
                        {item.warehouse_storage_capacity ? `${parseFloat(item.warehouse_storage_capacity || 0).toFixed(2)} MT` : ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.warehouse_storage_type ? (
                      <>
                        {item.warehouse_storage_type.length > 20
                          ? `${item.warehouse_storage_type.substring(0, 20)}...`
                          : item.warehouse_storage_type}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.warehuose_storage_status ? (
                      <>
                        {item.warehuose_storage_status.length > 20
                          ? `${item.warehuose_storage_status.substring(0, 20)}...`
                          : item.warehuose_storage_status}
                      </>
                    ) : (
                      ""
                    )
                  }
                  textClassname={
                    item?.warehuose_storage_status_id &&
                    (item?.warehuose_storage_status_id === 1
                      ? "success"
                      : item?.warehuose_storage_status_id === 2
                        ? "warning"
                        : item?.warehuose_storage_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.warehouse_storage_id}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

export const ReadOnlyEmployeeData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.user_account?.unique_identification_number}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item?.user_account?.unique_identification_number ? (
                      <>
                        {`${item.user_account}`.length > 20
                          ? `${`${item.user_account.unique_identification_number}`.substring(0, 20)}...`
                          : `${item.user_account.unique_identification_number}`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.user?.first_name ? `${item?.user?.first_name} ${item?.user?.surname}` : ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.employee_role?.role_name ? (
                      <>
                        {item?.employee_role?.role_name.length > 20
                          ? `${item?.employee_role?.role_name.substring(0, 20)}...`
                          : item?.employee_role?.role_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.employee_department?.department_name ? (
                      <>
                        {item?.employee_department?.department_name > 20
                          ? `${item?.employee_department?.department_name.substring(0, 20)}...`
                          : item?.employee_department?.department_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.user_account?.account_status ? (
                      <>
                        {item?.user_account?.account_status > 20
                          ? `${item?.user_account?.account_status.substring(0, 20)}...`
                          : item?.user_account?.account_status}
                      </>
                    ) : (
                      ""
                    )
                  }
                  textClassname={
                    item?.user_account?.account_status_id &&
                    (item?.user_account?.account_status_id === 1
                      ? "success"
                      : item?.user_account?.account_status_id === 2
                        ? "warning"
                        : item?.user_account?.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.warehouse_storage_id}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};


export const ReadOnlyDeliveryNoteProductData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.delivery_note_product_id}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item?.product_name ? (
                      <>
                        {item?.product_name.length > 20
                          ? `${`${item?.product_name}`.substring(0, 20)}...`
                          : `${item.product_name}`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.packaging_total_number ? item?.packaging_total_number : ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.unit_measurement_full_name ? (
                      <>
                        {item?.unit_measurement_full_name.length > 20
                          ? `${item?.unit_measurement_full_name.substring(0, 20)}...`
                          : item?.unit_measurement_full_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.total_capacity_selected_measurement ? (
                      <>
                        {item?.total_capacity_selected_measurement?.length > 20
                          ? `${item?.total_capacity_selected_measurement.substring(0, 20)}...`
                          : item?.total_capacity_selected_measurement}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.total_capacity_in_mt ? (
                      <>
                        {item?.total_capacity_in_mt?.length > 20
                          ? `${item?.total_capacity_in_mt.substring(0, 20)}...`
                          : item?.total_capacity_in_mt}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.warehouse_storage_id}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Read only User Account Data
export const ReadOnlyUserAccountData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.username}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item?.username ? (
                      <>
                        {item?.username.length > 20
                          ? `${`${item?.username}`.substring(0, 20)}...`
                          : `${item.username}`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.account_type ? item?.account_type : ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.created_date ?
                      moment(item?.created_date).format("MMMM Do, YYYY h:mm:ss") :
                      ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.account_status ? (
                      <>
                        {item.account_status.length > 20
                          ? `${item.account_status.substring(0, 20)}...`
                          : item.account_status}
                      </>
                    ) : (
                      ""
                    )
                  }
                  textClassname={
                    item?.account_status_id &&
                    (item?.account_status_id === 1
                      ? "success"
                      : item?.account_status_id === 2
                        ? "warning"
                        : item?.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.username}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Read only Delivery Note Products
export const ReadOnlyDeliveryNoteProductsData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.delivery_note_product_id}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item?.product_name ? (
                      <>
                        {item?.product_name.length > 20
                          ? `${`${item?.product_name}`.substring(0, 20)}...`
                          : `${item.product_name}`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.packaging_total_number ? item?.packaging_total_number : ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.unit_measurement_full_name ? (
                      <>
                        {item.unit_measurement_full_name.length > 20
                          ? `${`${item.unit_measurement_full_name} (${item.unit_measurement_abbrev})`.substring(0, 20)}...`
                          : `${item.unit_measurement_full_name} (${item.unit_measurement_abbrev})`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.total_capacity_selected_measurement ? (
                      <>
                        {item.total_capacity_selected_measurement ? `${parseFloat(item.total_capacity_selected_measurement || 0).toFixed(2)} ${item.unit_measurement_abbrev}` : ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.warehouse_storage_capacity ? (
                      <>
                        {item.total_capacity_in_mt ? `${parseFloat(item.total_capacity_in_mt || 0).toFixed(2)}` : ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.username}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Warehouse Inventory Branch List
export const ReadOnlyWarehouseInventoryBranchListData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
  action_function
}) => {

  const initial_actions_data = { adjust_inventory: false };

  const [selectedData, setSelectedData] = useState();
  const [account_actions, setAccountActions] = useState([]);
  const [componentData, setComponentData] = useState(initial_actions_data);

  useEffect(() => {
    if (localStorage.getItem("log_cred"))
    {
      setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
    }
  }, []);

  useEffect(() => {
    if (account_actions)
    {
      let actions_data = initial_actions_data;
      account_actions?.map((module) => {
        if (module.module_name === app_consts.WAREHOUSE_MANAGEMENT) 
        {
          // Actions Data
          if (findModuleAction(app_consts.ADJUST_INVENTORY, module))
          {
            actions_data.adjust_inventory = true;
          }
        }
      });
      setComponentData(actions_data);
    }
  }, [account_actions]);

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.branch_ref_no}>
                {/* <ReadOnlyTableRow
                  row_type="icon_col"
                  item={<div className="fs-12"><Icon name="chevron-down" className="fs-18"></Icon></div>}
                  setSelectedData={setSelectedData}
                  item_id={index}
                  icon_click_action={{ action: app_consts.LOAD, index: index, data: item }}
                /> */}
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.branch_area ? (
                      <>
                        {item.branch_area.length > 20
                          ? `${`${item.branch_area}`.substring(0, 20)}...`
                          : item.branch_area}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.storage_capacity ? (
                      <>
                        {item.storage_capacity ? `${parseFloat(item.storage_capacity || 0).toFixed(2)}` : ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.account_status ? (
                      <>
                        {item.account_status.length > 20
                          ? `${item.account_status.substring(0, 20)}...`
                          : item.account_status}
                      </>
                    ) : (
                      ""
                    )
                  }
                  textClassname={
                    item?.account_status &&
                    (item?.account_status_id === 1
                      ? "success"
                      : item?.account_status_id === 2
                        ? "warning"
                        : item?.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item?.branch_location_id}
                />
              </DataTableItem>
              {
                item?.storage_data?.length > 0 && (
                  <>
                    <DataTableHead  >
                      <DataTableRow className="bg-gray-200">
                        <span className="fw-medium fs-16px text-secondary">Storage Ref No</span>
                      </DataTableRow>
                      <DataTableRow className="bg-gray-200">
                        <span className="fw-medium fs-16px text-secondary">Storage Type</span>
                      </DataTableRow>
                      <DataTableRow className="bg-gray-200">
                        <span className="fw-medium fs-16px text-secondary">Capacity</span>
                      </DataTableRow>
                      <DataTableRow className="bg-gray-200">
                      </DataTableRow>
                    </DataTableHead>
                  </>
                )
              }
              {
                item?.storage_data?.map((storage, index) => {
                  return (
                    <>
                      <DataTableItem key={index} className="bg-gray-100">
                        <ReadOnlyTableRow
                          row_type="normal_col"
                          item={
                            storage.warehouse_storage_reference_number ? (
                              <>
                                {storage.warehouse_storage_reference_number.length > 20
                                  ? `${`${storage.warehouse_storage_reference_number}`.substring(0, 20)}...`
                                  : storage.warehouse_storage_reference_number}
                              </>
                            ) : (
                              ""
                            )
                          }
                        />
                        <ReadOnlyTableRow
                          row_type="normal_col"
                          item={
                            storage.warehouse_storage_type ? (
                              <>
                                {storage.warehouse_storage_type.length > 20
                                  ? `${`${storage.warehouse_storage_type}`.substring(0, 20)}...`
                                  : storage.warehouse_storage_type}
                              </>
                            ) : (
                              ""
                            )
                          }
                        />
                        <ReadOnlyTableRow
                          row_type="normal_col"
                          item={
                            storage.total_storage_capacity_for_product ? (
                              <>
                                {storage.total_storage_capacity_for_product ? `${parseFloat(storage.total_storage_capacity_for_product || 0).toFixed(2)}` : "0.00"}
                              </>
                            ) : (
                              "0.00"
                            )
                          }
                        />
                        {
                          componentData.adjust_inventory === true ?
                            <>
                              <ReadOnlyTableRow
                                row_type="action_col"
                                action_function={action_function}
                                item_id={storage?.warehouse_storage_id}
                                item="Adjust"
                              />
                            </>
                            :
                            <>
                              <ReadOnlyTableRow
                                row_type="normal_col"
                                item=""
                              />
                            </>
                        }
                      </DataTableItem>
                    </>
                  );
                })
              }
            </>
          );
        })}
    </>
  );
};

// Ready Only Warehouse Inventory Product History List
export const ReadOnlyWarehouseInventoryProductHistoryListData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={index}>
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    index !== data?.length - 1 ?
                      <>
                        {
                          parseFloat(data[index].balance) > parseFloat(data[index + 1].balance) ?
                            <>
                              <Icon name="caret-up-fill" className="text-success fs-22px" />
                            </>
                            :
                            parseFloat(data[index].balance) < parseFloat(data[index + 1].balance) ?
                              <>
                                <Icon name="caret-down-fill" className="text-danger fs-22px" />
                              </>
                              :
                              <>
                                <Icon name="minus" className="text-danger fs-22px" />
                              </>
                        }
                      </>
                      :
                      <>
                        <Icon name="minus" className="text-gray fs-22px" />
                      </>
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.date ?
                      moment(item?.date).format("YYYY-MM-DD,  h:mm:ss") :
                      ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.message ? (
                      <>
                        {item.message?.length > 40
                          ? `${`${item.message}`.substring(0, 40)}...`
                          : item.message}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.warehouse_storage_ref ? (
                      <>
                        {item.warehouse_storage_ref.length > 20
                          ? `${item.account_status.substring(0, 20)}...`
                          : item.warehouse_storage_ref}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.warehouse_storage_type ? (
                      <>
                        {item.warehouse_storage_type.length > 20
                          ? `${item.warehouse_storage_type.substring(0, 20)}...`
                          : item.warehouse_storage_type}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.capacity ? (
                      <>
                        {item.capacity ? `${parseFloat(item.capacity || 0).toFixed(2)}` : ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.balance ? (
                      <>
                        {item.balance ? `${parseFloat(item.balance || 0).toFixed(2)}` : ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={index}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Role Employees List
export const ReadOnlyRoleEmployeesListData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.user_account?.unique_identification_number}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item?.user_account?.unique_identification_number ? (
                      <>
                        {`${item.user_account}`.length > 20
                          ? `${`${item.user_account.unique_identification_number}`.substring(0, 20)}...`
                          : `${item.user_account.unique_identification_number}`}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.user?.first_name ? `${item?.user?.first_name} ${item?.user?.surname}` : ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.user_account?.account_status ? (
                      <>
                        {item?.user_account?.account_status > 20
                          ? `${item?.user_account?.account_status.substring(0, 20)}...`
                          : item?.user_account?.account_status}
                      </>
                    ) : (
                      ""
                    )
                  }
                  textClassname={
                    item?.user_account?.account_status_id &&
                    (item?.user_account?.account_status_id === 1
                      ? "success"
                      : item?.user_account?.account_status_id === 2
                        ? "warning"
                        : item?.user_account?.account_status_id === 3
                          ? "danger"
                          : "")
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_det_link"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item.warehouse_storage_id}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Goods Received Product List
export const ReadOnlyGoodsReceivedProductListData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.product_id}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item?.product_name ? (
                      <>
                        {item.product_name?.length > 20
                          ? `${`${item.product_name}`.substring(0, 20)}...`
                          : item?.product_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.unit_measurement_abbrev ? capitalizeWords(item?.unit_measurement_abbrev) : ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.packet_size ? item?.packet_size : ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.total_capacity_selected_measurement ? (
                      <>
                        {item.total_capacity_selected_measurement ? `${parseFloat(item.total_capacity_selected_measurement || 0).toFixed(2)}` : ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.total_capacity_in_mt ? (
                      <>
                        {item.total_capacity_in_mt ? `${parseFloat(item.total_capacity_in_mt || 0).toFixed(2)}` : ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only View  Goods Received Product List
export const ReadOnlyViewGoodsReceivedProductListData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {

  console.log(data);

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={item?.product_id}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={
                    item?.product_name ? (
                      <>
                        {item.product_name?.length > 20
                          ? `${`${item.product_name}`.substring(0, 20)}...`
                          : item?.product_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.unit_measurement_full_name ? (
                      <>
                        {item?.unit_measurement_full_name.length > 20
                          ? `${item?.unit_measurement_full_name.substring(0, 20)}...`
                          : item?.unit_measurement_full_name}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.packet_size ? item?.packet_size : "--"
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.total_capacity_selected_measurement ? (
                      <>
                        {item.total_capacity_selected_measurement ? `${parseFloat(item.total_capacity_selected_measurement || 0).toFixed(2)}` : "--"}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.total_capacity_selected_measurement ? (
                      <>
                        {item.product_selected_measurement ? formatScientificNotation(item.product_selected_measurement) : "--"}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.total_capacity_in_mt ? (
                      <>
                        {item.total_capacity_in_mt ? `${parseFloat(item.total_capacity_in_mt || 0).toFixed(2)}` : "--"}
                      </>
                    ) : (
                      ""
                    )
                  }
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Loading Order product List Data
export const ReadOnlyLoadingOrderProductListtData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
  setSelectedData,
}) => {
  console.log(data);
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={index}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={item?.product_name}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.delivery_name}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.branch_location}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.packet_size}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.total_capacity_selected_measurement}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.total_mt_capacity}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.product_status}
                  textClassname={
                    item?.product_status &&
                    (item?.product_status === app_consts.LOADED
                      ? "success text-capitalize"
                      : item?.product_status === app_consts.NOT_LOADED
                        ? "warning text-capitalize"
                        : "")
                  }
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Loading Order product List Data
export const ReadOnlyDNProductNotLoadedData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
  setSelectedData,
}) => {

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={index}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={item?.product_name}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.packaging_total_number}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.total_capacity_selected_measurement}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.total_mt_capacity}
                />
                <ReadOnlyTableRow
                  row_type="icon_col"
                  item={<Button size="sm" type="submit" color="secondary" >Load</Button>}
                  setSelectedData={setSelectedData}
                  item_id={index}
                  icon_click_action={{ action: app_consts.LOAD, index: index, data: item }}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Loading Order product List Data
export const ReadOnlyDNProductNotLoadedEditableData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
  setSelectedData,
}) => {

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={index}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={item?.product_name}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.packaging_total_number}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.total_capacity_selected_measurement}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.total_mt_capacity}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.amount_left || item?.total_mt_capacity}
                />
                <ReadOnlyTableRow
                  row_type="icon_col"
                  item={<Button size="sm" type="submit" color="secondary" >Load</Button>}
                  setSelectedData={setSelectedData}
                  item_id={index}
                  icon_click_action={{ action: app_consts.LOAD, index: index, data: item }}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Driver's Instructions Editable List
export const ReadOnlyDriversInstructionsListEditData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
  setSelectedData
}) => {

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={index}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={`${index + 1}.`}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item}
                />
                <ReadOnlyTableRow
                  row_type="icon_col"
                  item_id={index}
                  item={<Icon name="edit-alt" className="text-primary fs-22px" style={{ cursor: "pointer" }} />}
                  setSelectedData={setSelectedData}
                  icon_click_action={{ action: app_consts.EDIT, index: index }}
                />
                <ReadOnlyTableRow
                  row_type="icon_col"
                  item={<Icon name="trash-fill" className="text-danger fs-22px" style={{ cursor: "pointer" }} />}
                  setSelectedData={setSelectedData}
                  item_id={index}
                  icon_click_action={{ action: app_consts.DELETE, index: index }}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Driver's Instructions List
export const ReadOnlyDriversInstructionsListData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={index}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={`${index + 1}.`}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Onboard Company User Search List
export const ReadOnlyOnboardCompanyUserSearchData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
  setSelectedData
}) => {

  const [selected_data_set, setSelectedDataSet] = useState(setSelectedDataID);

  useEffect(() => {
    setSelectedDataID(selected_data_set);
  }, [selected_data_set]);

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <div style={{ cursor: "pointer", display: "contents" }} onClick={() => { setSelectedDataSet(item.user_id); setSelectedData(item); }}>
                <DataTableItem key={item.user_id} className={`${selected_data_set === item.user_id ? "bg-gray-500 text-light" : ""}`}>
                  <ReadOnlyTableRow
                    row_type="first_col"
                    item={
                      item.first_name ? (
                        <>
                          {`${item.first_name} ${item.surname}`.length > 30
                            ? `${`${item.first_name} ${item.surname}`.substring(0, 30)}...`
                            : `${item.first_name} ${item.surname}`}
                        </>
                      ) : (
                        ""
                      )
                    }
                    textClassname={`${selected_data_set === item.user_id ? "text-white" : ""}`}
                  />
                  <ReadOnlyTableRow
                    row_type="normal_col"
                    item={
                      item.phone_number ? (
                        <>
                          {item.phone_number.length > 30 ? `${item.phone_number.substring(0, 30)}...` : item.phone_number}
                        </>
                      ) : (
                        ""
                      )
                    }
                    textClassname={`${selected_data_set === item.user_id ? "white" : ""}`}
                  />
                  <ReadOnlyTableRow
                    row_type="normal_col"
                    item={
                      item.email_address ? (
                        <>
                          {item.email_address.length > 30 ? `${item.email_address.substring(0, 30)}...` : item.email_address || "--"}
                        </>
                      ) : (
                        ""
                      )
                    }
                    textClassname={`${selected_data_set === item.user_id ? "white" : ""}`}
                  />
                </DataTableItem>
              </div>
            </>
          );
        })}
    </>
  );
};

// Ready Only Grn HIstory List
export const ReadOnlyGrnHistoryData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
}) => {
  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={index}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={`${index + 1}.`}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.message ? (
                      <>
                        {item.message.length > 30
                          ? `${item.message.substring(0, 30)}...`
                          : item.message || "--"}
                      </>
                    ) : (
                      "--"
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={`${item?.issued_by_name} (${item?.issued_by_acc_id})`}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item?.timestamp ?
                      moment(item?.timestamp).format("MMMM Do, YYYY h:mm:ss") :
                      ""
                  }
                />
                <ReadOnlyTableRow
                  row_type="view_col"
                  setViewModal={setViewModal}
                  setSelectedDataID={setSelectedDataID}
                  item_id={item?.timestamp}
                />
              </DataTableItem>
            </>
          );
        })}
    </>
  );
};

// Ready Only Add Edit Delete UM List
export const ReadOnlyGrnAddEditDeleteUMData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
  action_function,
  setSelectedData
}) => {

  const [deleteClick, setDeleteClick] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState("");

  const delete_btn_click = ({ key, packet_size }) => {
    setDeleteClick(true);
    setSelectedDelete(packet_size);
  };

  const close_delete_question = ({ key, packet_size }) => {
    setDeleteClick(false);
    setSelectedDelete("");
  };

  const delete_submit_function = ({ key, packet_size }) => {
    action_function(key, "delete", packet_size);
    setDeleteClick(false);
    setSelectedDelete("");
  };


  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <DataTableItem key={index}>
                <ReadOnlyTableRow
                  row_type="first_col"
                  item={`${index + 1}.`}
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={
                    item.packet_size ? (
                      <>
                        {item.packet_size.length > 30
                          ? `${item.packet_size.substring(0, 30)}...`
                          : item.packet_size || "--"}
                      </>
                    ) : (
                      "--"
                    )
                  }
                />
                <ReadOnlyTableRow
                  row_type="normal_col"
                  item={item?.measurement_in_kilos ? item?.measurement_in_kilos : "---"}
                />
                <ReadOnlyTableRow
                  row_type="icon_col"
                  item_id={index}
                  item={<Icon name="edit-alt" className="text-primary fs-22px" style={{ cursor: "pointer" }} />}
                  setSelectedData={setSelectedData}
                  icon_click_action={{ key: item?.key, packet_size: item?.packet_size, measurement_in_kilos: item?.measurement_in_kilos }}
                />
                <ReadOnlyTableRow
                  row_type="icon_col"
                  item={<Icon name="trash-fill" className="text-danger fs-22px" style={{ cursor: "pointer" }} />}
                  setSelectedData={delete_btn_click}
                  item_id={index}
                  icon_click_action={{ key: item?.key, packet_size: item?.packet_size }}
                />
              </DataTableItem>
              {deleteClick && item?.packet_size === selectedDelete &&
                (
                  <>
                    <DataTableItem key={index}>
                      <ReadOnlyTableRow
                        row_type="first_col"
                        item=""
                      />
                      <ReadOnlyTableRow
                        row_type="normal_col"
                        item={`Are you Sure that you want to Delete ${selectedDelete}?`}
                      />
                      <ReadOnlyTableRow
                        row_type="normal_col"
                        item=""
                      />
                      <ReadOnlyTableRow
                        row_type="icon_col"
                        item={<Button color="gray"
                          onClick={(e) => { e.preventDefault(); }}
                        >
                          No
                        </Button>}
                        setSelectedData={close_delete_question}
                        item_id={index}
                        icon_click_action={{ key: item?.key, packet_size: item?.packet_size }}
                      />
                      <ReadOnlyTableRow
                        row_type="icon_col"
                        item_id={index}
                        item={<Button color="danger"
                          onClick={(e) => { e.preventDefault(); }}
                        >
                          Yes
                        </Button>}
                        setSelectedData={delete_submit_function}
                        icon_click_action={{ packet_size: item?.packet_size, key: item?.key }}
                      />
                    </DataTableItem>
                  </>
                )
              }
            </>
          );
        })}
    </>
  );
};

// Ready Only Onboard Company User Search List
export const ReadOnlyAddEmployeeToBranchData = ({
  data,
  currentItems,
  setViewModal,
  amendFunction,
  setOpendModalDetails,
  setSelectedDataID,
  setSelectedData
}) => {

  const [selected_data_set, setSelectedDataSet] = useState(setSelectedDataID);

  useEffect(() => {
    setSelectedDataID(selected_data_set);
  }, [selected_data_set]);

  return (
    <>
      {data?.length > 0 &&
        currentItems?.map((item, index) => {
          return (
            <>
              <div style={{ cursor: "pointer", display: "contents" }} onClick={() => { setSelectedDataSet(item?.employee?.employee_id); setSelectedData(item); }}>
                <DataTableItem key={item?.employee?.employee_id} className={`${selected_data_set === item?.employee?.employee_id ? "bg-gray-500 text-light" : ""}`}>
                  <ReadOnlyTableRow
                    row_type="first_col"
                    item={
                      item?.user?.first_name ? (
                        <>
                          {`${item?.user?.first_name} ${item?.user?.surname}`.length > 30
                            ? `${`${item?.user?.first_name} ${item?.user?.surname}`.substring(0, 30)}...`
                            : `${item?.user?.first_name} ${item?.user?.surname}`}
                        </>
                      ) : (
                        ""
                      )
                    }
                    textClassname={`${selected_data_set === item?.employee?.employee_id ? "text-white" : ""}`}
                  />
                  <ReadOnlyTableRow
                    row_type="normal_col"
                    item={
                      item.employee_department?.department_name ? (
                        <>
                          {item.employee_department?.department_name?.length > 30 ? `${item.employee_department?.department_name.substring(0, 30)}...` : item.employee_department?.department_name}
                        </>
                      ) : (
                        ""
                      )
                    }
                    textClassname={`${selected_data_set === item?.employee?.employee_id ? "white" : ""}`}
                  />
                  <ReadOnlyTableRow
                    row_type="normal_col"
                    item={
                      item?.employee_role?.role_name ? (
                        <>
                          {item?.employee_role?.role_name?.length > 30 ? `${item?.employee_role?.role_name.substring(0, 30)}...` : item?.employee_role?.role_name || "--"}
                        </>
                      ) : (
                        ""
                      )
                    }
                    textClassname={`${selected_data_set === item?.employee?.employee_id ? "white" : ""}`}
                  />
                </DataTableItem>
              </div>
            </>
          );
        })}
    </>
  );
};