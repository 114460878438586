import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon, Row } from "../../../../../../../../components/Component";
import TextInput from "../../../../../../../components/inputs/TextInput";
import Dropzone from "react-dropzone";
import { document_read, messageToast } from "../../../../../../../../utils/Utils";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { EMAIL_REGEX, NUMBERS_ONLY_REGEX, ZAMIBAN_PHONE_NUMBER_REGEX } from "../../../../../../../../utils/RegexUtils";
import ModalPDFViewer from "../../../../../modal/modal_pdf_viewer/ModalPDFViewer";
import { useParams } from "react-router";
import { CREATE_COMPANY_ACCOUNT } from "../../../../../../../../api/api_data";
import { useDispatch } from "react-redux";
import { employee_dashboard_actions_helper } from "../../../../../../../../actions/actions_helper";
import {
  fail_create_compnay_account,
  loading_create_compnay_account,
  reset_create_compnay_account,
  success_create_compnay_account,
} from "../../../../../../../../reducers/dashboard_reducers/user_accounts/create_compnay_account_Slice";
import {
  CREATE_COMPANY_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG,
  CREATE_COMPANY_USER_ACCOUNT_LIST_ERR_MSG,
  CREATE_COMPANY_USER_ACCOUNT_LIST_SUCCESS_MSG,
} from "../../../../../../../../constants/success_error_messages_consts";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";

const initialState = {
  company_name: "",
  pacra_registration_number: "",
  tax_number: "",
  company_email_address: "",
  company_mobile_number: "",
  company_pacra_doc: "",
  company_tpin_doc: "",
  phone_number: "",
};
const initialPageImages = { company_mobile_number: "", company_pacra_doc: "", company_tpin_doc: "" };

const OnboardCompanyStep = ({ togglestep, nextStep, data, setUniqueID }) => {
  const { phone_number } = useParams();
  const dispatch = useDispatch();
  const [errorVal, setError] = useState("");
  const [formData, setFormData] = useState(initialState);
  const [company_pacra_doc, setCompanyPacraDoc] = useState([]);
  const [company_tpin_doc, setTpinDoc] = useState([]);
  const [page_images, setPageImages] = useState(initialPageImages);
  const [clickedImage, setClickedImage] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [loading, setIsLoading] = useState(false);

  const [pacra_preview, setPacraPreview] = useState([]);
  const [tpin_preview, setTpinPreview] = useState([]);

  // On Page Load
  useEffect(() => {
    setFormData({ ...formData, ["phone_number"]: phone_number });
  }, [phone_number]);

  // Function to Handle Document Upload
  const handleDropChange = (acceptedFiles, set, type) => {
    // convert to base 64 and store in State
    const document = acceptedFiles[0];
    if (!document)
    {
      messageToast("Select valid File (.pdf).", app_consts.FAIL);
      return false;
    }
    if (!document.name.match(/\.(pdf)$/))
    {
      messageToast("Select valid File (.pdf).", app_consts.FAIL);
      return false;
    } else
    {
      setFormData({ ...formData, [type]: document });
      setPageImages({ ...page_images, [type]: document });
      // Reading Doc
      document_read(document, (document_view) => {
        setPageImages({ ...page_images, [type]: document_view });
      });
      set(Object.assign(document, {
        preview: URL.createObjectURL(document),
      })
      );

      // if (type === )
      //   {
      //     setPacraPreview()
      //   }
      //   else if (type ===)
      //     {
      //       setTpinPreview()
      //     }
    }
  };

  //Checking Valid Phone Number
  const [comp_phone_number, setPhoneNumber] = useState("");
  const [validPNumber, setValidPNumber] = useState(false);
  const [inputPNumberFocus, setInputPNumberFocus] = useState(false);

  //Checking Valid Email Address
  const [email_Address, setemail_Address] = useState("");
  const [validEmail_Address, setValidEmail_Address] = useState(false);
  const [inputEmail_AddressFocus, setInputEmail_AddressFocus] = useState(false);

  //Checking Valid Tax Number
  const [tax_number, setTax_Number] = useState("");
  const [validtTax_Number, setValidTax_Number] = useState(false);
  const [inputtTax_NumberFocus, setInputTax_NumberFocus] = useState(false);

  //Checking Valid Pacra Number
  const [pacra_number, setPacra_Number] = useState("");
  const [validPacra_Number, setValidPacra_Number] = useState(false);
  const [inputPacra_NumberFocus, setInputPacra_NumberFocus] = useState(false);

  // Image Modal
  const viewImage = (title) => {
    setOpenPreview(true);
    setModalTitle(title);
  };

  // Handle Input Changes
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "pacra_registration_number")
    {
      setPacra_Number(value);
    } else if (e.target.name === "tax_number")
    {
      setTax_Number(value);
    } else if (e.target.name === "company_email_address")
    {
      setemail_Address(value);
    } else if (e.target.name === "company_mobile_number")
    {
      setPhoneNumber(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  // Listen to Changes So that they Can be Validated

  //Checking States to see if Pacra Number valid or not
  useEffect(() => {
    const result = NUMBERS_ONLY_REGEX.test(pacra_number);
    if (pacra_number.length !== 12)
    {
      setValidPacra_Number(false);
    } else if (pacra_number.length === 12)
    {
      setValidPacra_Number(result);
    }
  }, [pacra_number]);

  //Checking States to see if Tax Number valid or not
  useEffect(() => {
    const result = NUMBERS_ONLY_REGEX.test(tax_number);
    if (tax_number.length !== 10)
    {
      setValidTax_Number(false);
    } else if (tax_number.length === 10)
    {
      setValidTax_Number(result);
    }
  }, [tax_number]);

  //Checking States to see if Email Address valid or not
  useEffect(() => {
    const result = EMAIL_REGEX.test(email_Address);
    setValidEmail_Address(result);
  }, [email_Address]);

  //Checking States to see if Phone Number is valid or not
  useEffect(() => {
    const result = ZAMIBAN_PHONE_NUMBER_REGEX.test(comp_phone_number);
    setValidPNumber(result);
  }, [comp_phone_number]);

  // Handle Submit
  const handleSubmit = () => {
    setError("");
    setIsLoading(true);
    dispatch(
      employee_dashboard_actions_helper(
        loading_create_compnay_account,
        success_create_compnay_account,
        fail_create_compnay_account,
        CREATE_COMPANY_USER_ACCOUNT_LIST_ERR_MSG,
        CREATE_COMPANY_USER_ACCOUNT_LIST_SUCCESS_MSG,
        app_consts.POST,
        CREATE_COMPANY_ACCOUNT,
        formData,
        CREATE_COMPANY_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Reducers For Creating Customer Account
  const create_compnay_account = useSelector((state) => state.create_compnay_account.create_compnay_account);
  //
  useEffect(() => {
    if (create_compnay_account.status !== app_consts.IDLE)
    {
      if (create_compnay_account.status === app_consts.LOADING)
      {
        setIsLoading(true);
      } else
      {
        setIsLoading(false);
        if (create_compnay_account.status === app_consts.FAIL)
        {
          messageToast(create_compnay_account.message, app_consts.FAIL);
          setError(create_compnay_account.message, app_consts.FAIL);
        } else if (create_compnay_account.status === app_consts.SUCCESS)
        {
          setUniqueID(create_compnay_account.data);
          messageToast(CREATE_COMPANY_USER_ACCOUNT_LIST_SUCCESS_MSG, app_consts.SUCCESS);
          nextStep();
        }
        dispatch(reset_create_compnay_account());
      }
    }
  }, [create_compnay_account]);

  // filter country iso from phone NUmber
  const handlePhoneNumberChange = (country, phone) => {
    setFormData({ ...formData, ["iso_code_2"]: phone.countryCode.toUpperCase(), ["company_mobile_number"]: country });
  };

  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Onboard Company</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row className="g-4">
            <TextInput
              label="Company Name"
              type="text"
              id_val="company_name"
              value={formData.company_name}
              handleChange={handleChange}
              name="company_name"
              maxlength="100"
              required
            />
            <TextInput
              label="Tax Number"
              type="text"
              id_val="tax_number"
              value={formData.tax_number}
              handleChange={handleChange}
              name="tax_number"
              required
              maxlength="10"
              error_stat={inputtTax_NumberFocus && tax_number && !validtTax_Number ? true : false}
              err_Msg={"Tax Number Should consist of 10 Numerical Characters."}
              onFocus={() => setInputTax_NumberFocus(true)}
            />
            <Col lg="6">
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="company_mobile_number">
                    Company Mobile Number<span className='text-danger pr-2'>{` *`}</span>
                  </label>
                </div>
                <div className="form-control-wrap">
                  <PhoneInput
                    country={"zm"}
                    value={formData.company_mobile_number}
                    onChange={(country, phone) => handlePhoneNumberChange(country, phone)}
                    copyNumbersOnly={false}
                    inputStyle={{ width: '100%' }}
                  />
                </div>
              </div>
            </Col>
            <span className="fs-18px text-base fw-bold">Optional</span>
            <TextInput
              label="Company Email Address"
              type="text"
              id_val="company_email_address"
              value={formData.company_email_address}
              handleChange={handleChange}
              name="company_email_address"
              maxlength="160"
              error_stat={inputEmail_AddressFocus && email_Address && !validEmail_Address ? true : false}
              err_Msg={"Please Insert a Valid Email Address."}
              onFocus={() => setInputEmail_AddressFocus(true)}
            />
            <TextInput
              label="Pacra Registration Number"
              type="text"
              id_val="pacra_registration_number"
              value={formData.pacra_registration_number}
              handleChange={handleChange}
              name="pacra_registration_number"
              maxlength="12"
              error_stat={inputPacra_NumberFocus && pacra_number && !validPacra_Number ? true : false}
              err_Msg="Pacra Registration Number Should consist of 12 Numerical Characters."
              onFocus={() => setInputPacra_NumberFocus(true)}
            />
            <Col size="12">
              <Row className="g-gs flex mb-2">
                <Col sm="6">
                  <label className="form-label">Registration Document</label>
                  <Dropzone
                    onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setCompanyPacraDoc, "company_pacra_doc")}
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                          <input {...getInputProps()} />
                          {formData.company_pacra_doc.length === 0 && (
                            <div className="dz-message">
                              <span className="dz-message-text">Drag and drop file</span>
                              <span className="dz-message-or">or</span>
                              <Button color="primary">SELECT</Button>
                            </div>
                          )}
                          {company_pacra_doc && company_pacra_doc?.length > 0 && (
                            <div
                              key={company_pacra_doc.name}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                              <div className="dz-image">
                                <img src={company_pacra_doc?.preview} alt="preview" />
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {page_images.company_pacra_doc ? (
                    <>
                      <div className="form-group mt-2">
                        <div className="form-control-wrap-flex-wrap justify-end">
                          <Button
                            onClick={(ev) => {
                              setClickedImage(company_pacra_doc?.preview);
                              viewImage("Registration Document");
                            }}
                            color="light"
                            outline
                            className="bg-white"
                            size="md"
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
                <Col sm="6">
                  <label className="form-label">Tax Document</label>
                  <Dropzone
                    onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setTpinDoc, "company_tpin_doc")}
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                          <input {...getInputProps()} />
                          {formData.company_tpin_doc.length === 0 && (
                            <div className="dz-message">
                              <span className="dz-message-text">Drag and drop file</span>
                              <span className="dz-message-or">or</span>
                              <Button color="primary">SELECT</Button>
                            </div>
                          )}
                          {company_tpin_doc && company_tpin_doc?.length > 0 && (
                            <div
                              key={company_tpin_doc.name}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                              <div className="dz-image">
                                <img src={company_tpin_doc?.preview} alt="preview" />
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {page_images.company_tpin_doc ? (
                    <>
                      <div className="form-group mt-2">
                        <div className="form-control-wrap-flex-wrap justify-end">
                          <Button
                            onClick={(ev) => {
                              setClickedImage(company_tpin_doc?.preview);
                              viewImage("Tax Document");
                            }}
                            color="light"
                            outline
                            className="bg-white"
                            size="md"
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Col>
            <Col xl="12" className="d-flex justify-content-end">
              <Button
                onClick={handleSubmit}
                size="lg"
                type="submit"
                color="primary"
                disabled={
                  loading
                    ? loading
                    : !(
                      formData.company_mobile_number &&
                      validtTax_Number &&
                      formData.company_name
                    )
                }
              >
                {loading ? <Spinner size="sm" color="light" /> : "Onboard Company"}
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
      <ModalPDFViewer openPreview={openPreview} setOpenPreview={setOpenPreview} title={modalTitle} pdf={clickedImage} />
    </>
  );
};

export default OnboardCompanyStep;
