import React, { useEffect, useState } from "react";
import {
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  Icon,
  PaginationComponent,
  RSelect,
} from "../../../../components/Component";
import { left_select_options } from "./table_list_data/table_list_heading";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { user_list_column } from "./table_list_data/table_list_columns";
import {
  BranchLocationRows,
  ClientDeliveryNoteListRows,
  ClientGoodsReceivedListRows,
  CompanyUserAccountListRows,
  CustomerUserAccountListRows,
  DeliveryNoteTicketListRows,
  EmpDeptRolesListRows,
  EmployeeListRows,
  GoodsReceivedWeighBridgeTicketListRows,
  LoadingOrderListRows,
  ProductRows,
  ProductTypeRows,
  TruckDriverUserAccountListRows,
  UnitMeasurementRows,
  UserListRows,
  WarehouseInventoryListRows,
} from "./table_list_data/table_list_rows";
import * as app_consts from "../../../../constants/react_app_consts";

const TableList = ({
  left_head_select,
  type,
  data,
  rowOptionsData,
  amendFunc,
  setOpendModalDetails,
  setSelectedData,
  placeholder,
  search_placeholder,
  searchFunction
}) => {
  const [left_select_data, setLeftSelectData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [tableData, setTableData] = useState(data);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("dsc");

  const [rowOptData, setRowOptData] = useState(rowOptionsData);
  // ----
  useEffect(() => {
    setLeftSelectData(left_select_options(type));
    setColumnData(user_list_column(type));
  }, [type]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setRowOptData(rowOptionsData);
  }, [rowOptionsData]);

  // Search Button Code
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setOnSeachText] = useState("");
  // Toggle Search
  const toggleSearch = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Sorting


  // Sorting data
  const sortFunc = () => {
    if (type === app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST)
    {
      let sortedData;

      if (sort === "dsc")
      {
        sortedData = [...tableData].sort((a, b) =>
          b.goods_received.goods_received_ref_number.localeCompare(a.goods_received.goods_received_ref_number)
        );
      } else if (sort === "asc")
      {
        sortedData = [...tableData].sort((a, b) =>
          a.goods_received.goods_received_ref_number.localeCompare(b.goods_received.goods_received_ref_number)
        );
      }

      // Make sure the data is being properly set
      if (sortedData)
      {
        setTableData(sortedData);
      }
    }
  };

  return (
    <>
      <DataTable className="card-stretch">
        <div className="card-inner position-relative card-tools-toggle">
          <div className="card-title-group">
            <div className="card-tools">
              <div className="form-inline flex-nowrap gx-3">
                <div className="form-wrap">
                  {left_head_select && (
                    <RSelect
                      options={left_select_data}
                      className="w-130px"
                      placeholder={placeholder}
                    // onChange={(e) => onActionText(e)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="card-tools me-n1">
              <ul className="btn-toolbar gx-1">
                <li>
                  <a
                    href="#search"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleSearch();
                    }}
                    className="btn btn-icon search-toggle toggle-search"
                  >
                    <Icon name="search"></Icon>
                  </a>
                </li>
                <li className="btn-toolbar-sep"></li>
                {
                  // Advanced Filter
                }
                {/* Sorting Code */}
                <li>
                  <UncontrolledDropdown>
                    <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                      <Icon name="setting"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end className="dropdown-menu-xs">
                      <ul className="link-check">
                        <li>
                          <span>Show</span>
                        </li>
                        <li className={itemPerPage === 10 ? "active" : ""} >
                          <DropdownItem
                            tag="a"
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setItemPerPage(10);
                            }}
                          >
                            10
                          </DropdownItem>
                        </li>
                        <li className={itemPerPage === 15 ? "active" : ""} >
                          <DropdownItem
                            tag="a"
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setItemPerPage(15);
                            }}
                          >
                            15
                          </DropdownItem>
                        </li>
                      </ul>
                      <ul className="link-check">
                        <li>
                          <span>Order</span>
                        </li>
                        <li className={sort === "dsc" ? "active" : ""} >
                          <DropdownItem
                            tag="a"
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setSortState("dsc");
                              sortFunc();
                            }}
                          >
                            DESC
                          </DropdownItem>
                        </li>
                        <li className={sort === "asc" ? "active" : ""} >
                          <DropdownItem
                            tag="a"
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setSortState("asc");
                              sortFunc();
                            }}
                          >
                            ASC
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </ul>
            </div>
          </div>
          <div className={`card-search search-wrap ${!onSearch && "active"}`}>
            <div className="card-body">
              <div className="search-content">
                <Button
                  className="search-back btn-icon toggle-search active"
                  onClick={() => {
                    setOnSeachText("");
                    searchFunction(null);
                    toggleSearch();
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
                <input
                  type="text"
                  className="border-transparent form-focus-none form-control"
                  placeholder={search_placeholder || "Search by user or email"}
                  value={onSearchText}
                  onChange={(e) => { setOnSeachText(e.target.value); searchFunction(e.target.value); }}
                />
                <Button className="search-submit btn-icon">
                  <Icon name="search"></Icon>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <DataTableBody>
          <DataTableHead>
            {columnData &&
              columnData?.map((column) => {
                return (
                  <DataTableRow key={column.key}>
                    <span>{column.label}</span>
                  </DataTableRow>
                );
              })}
            <DataTableRow className="nk-tb-col-tools">&nbsp;</DataTableRow>
          </DataTableHead>
          {/* Table Rows */}
          {type === app_consts.USER_LIST_TYPE ? (
            <>
              <UserListRows
                data={tableData}
                currentItems={currentItems}
                rowOptionsData={rowOptData}
                amendFunction={amendFunc}
                setOpendModalDetails={setOpendModalDetails}
                setSelectedData={setSelectedData}
              />
            </>
          ) : // Branch Locations
            type === app_consts.BRANCH_LOCATION_LIST ? (
              <>
                <BranchLocationRows
                  data={tableData}
                  currentItems={currentItems}
                  rowOptionsData={rowOptData}
                  amendFunction={amendFunc}
                  setOpendModalDetails={setOpendModalDetails}
                  setSelectedData={setSelectedData}
                  type={type}
                />
              </>
            ) : //Product list
              type === app_consts.PRODUCT_LIST ? (
                <>
                  <ProductRows
                    data={tableData}
                    currentItems={currentItems}
                    rowOptionsData={rowOptData}
                    amendFunction={amendFunc}
                    setOpendModalDetails={setOpendModalDetails}
                    setSelectedData={setSelectedData}
                    type={type}
                  />
                </>
              ) : // Product Type List
                type === app_consts.PRODUCT_TYPE_LIST ? (
                  <>
                    <ProductTypeRows
                      data={tableData}
                      currentItems={currentItems}
                      rowOptionsData={rowOptData}
                      amendFunction={amendFunc}
                      setOpendModalDetails={setOpendModalDetails}
                      setSelectedData={setSelectedData}
                      type={type}
                    />
                  </>
                ) : // Unit Measurement List
                  type === app_consts.UNIT_MEASUREMENT_LIST ? (
                    <>
                      <UnitMeasurementRows
                        data={tableData}
                        currentItems={currentItems}
                        rowOptionsData={rowOptData}
                        amendFunction={amendFunc}
                        setOpendModalDetails={setOpendModalDetails}
                        setSelectedData={setSelectedData}
                        type={type}
                      />
                    </>
                  ) : // Employee List
                    type === app_consts.EMPLOYEE_LIST_TYPE ? (
                      <>
                        <EmployeeListRows
                          data={tableData}
                          currentItems={currentItems}
                          rowOptionsData={rowOptData}
                          amendFunction={amendFunc}
                          setOpendModalDetails={setOpendModalDetails}
                          setSelectedData={setSelectedData}
                          type={type}
                        />
                      </>
                    ) : // Goods Received Weigh Bridge Ticket List
                      type === app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST ? (
                        <>
                          <GoodsReceivedWeighBridgeTicketListRows
                            data={tableData}
                            currentItems={currentItems}
                            rowOptionsData={rowOptData}
                            amendFunction={amendFunc}
                            setOpendModalDetails={setOpendModalDetails}
                            setSelectedData={setSelectedData}
                            type={type}
                          />
                        </>
                      ) : // Warehouse Inventory List
                        type === app_consts.WAREHOUSE_INVENTORY_LIST ? (
                          <>
                            <WarehouseInventoryListRows
                              data={tableData}
                              currentItems={currentItems}
                              rowOptionsData={rowOptData}
                              amendFunction={amendFunc}
                              setOpendModalDetails={setOpendModalDetails}
                              setSelectedData={setSelectedData}
                              type={type}
                            />
                          </>
                        ) : // Delivery Note Ticket List
                          type === app_consts.DELIVERY_NOTES_WEIGH_BRIDGE_TICKET_LIST ? (
                            <>
                              <DeliveryNoteTicketListRows
                                data={tableData}
                                currentItems={currentItems}
                                rowOptionsData={rowOptData}
                                amendFunction={amendFunc}
                                setOpendModalDetails={setOpendModalDetails}
                                setSelectedData={setSelectedData}
                                type={type}
                              />
                            </>
                          ) : // Client Goods Received List
                            type === app_consts.CLIENT_GOODS_RECEIVED_LIST ? (
                              <>
                                <ClientGoodsReceivedListRows
                                  data={tableData}
                                  currentItems={currentItems}
                                  rowOptionsData={rowOptData}
                                  amendFunction={amendFunc}
                                  setOpendModalDetails={setOpendModalDetails}
                                  setSelectedData={setSelectedData}
                                  type={type}
                                />
                              </>
                            ) : // Customer User Account List
                              type === app_consts.CUSTOMER_USER_ACCOUNT_LIST ? (
                                <>
                                  <CustomerUserAccountListRows
                                    data={tableData}
                                    currentItems={currentItems}
                                    rowOptionsData={rowOptData}
                                    amendFunction={amendFunc}
                                    setOpendModalDetails={setOpendModalDetails}
                                    setSelectedData={setSelectedData}
                                    type={type}
                                  />
                                </>
                              ) : // Client Delivery Notes List
                                type === app_consts.CLIENT_DELIVERY_NOTES_LIST ? (
                                  <>
                                    <ClientDeliveryNoteListRows
                                      data={tableData}
                                      currentItems={currentItems}
                                      rowOptionsData={rowOptData}
                                      amendFunction={amendFunc}
                                      setOpendModalDetails={setOpendModalDetails}
                                      setSelectedData={setSelectedData}
                                      type={type}
                                    />
                                  </>
                                ) : // Company account List
                                  type === app_consts.COMPANY_USER_ACCOUNT_LIST ? (
                                    <>
                                      <CompanyUserAccountListRows
                                        data={tableData}
                                        currentItems={currentItems}
                                        rowOptionsData={rowOptData}
                                        amendFunction={amendFunc}
                                        setOpendModalDetails={setOpendModalDetails}
                                        setSelectedData={setSelectedData}
                                        type={type}
                                      />
                                    </>
                                  ) : // Truck Driver account List
                                    type === app_consts.TRUCK_DRIVER_USER_ACCOUNT_LIST ? (
                                      <>
                                        <TruckDriverUserAccountListRows
                                          data={tableData}
                                          currentItems={currentItems}
                                          rowOptionsData={rowOptData}
                                          amendFunction={amendFunc}
                                          setOpendModalDetails={setOpendModalDetails}
                                          setSelectedData={setSelectedData}
                                          type={type}
                                        />
                                      </>
                                    ) : // Employee Department and Roles List
                                      type === app_consts.DEPARTMENT_AND_ROLES_LIST ? (
                                        <>
                                          <EmpDeptRolesListRows
                                            data={tableData}
                                            currentItems={currentItems}
                                            rowOptionsData={rowOptData}
                                            amendFunction={amendFunc}
                                            setOpendModalDetails={setOpendModalDetails}
                                            setSelectedData={setSelectedData}
                                            type={type}
                                          />
                                        </>
                                      ) : // Loading Order List
                                        type === app_consts.LOADING_ORDERS_LIST ? (
                                          <>
                                            <LoadingOrderListRows
                                              data={tableData}
                                              currentItems={currentItems}
                                              rowOptionsData={rowOptData}
                                              amendFunction={amendFunc}
                                              setOpendModalDetails={setOpendModalDetails}
                                              setSelectedData={setSelectedData}
                                              type={type}
                                            />
                                          </>
                                        ) : (
                                          ""
                                        )}
        </DataTableBody>

        {/* Pagination */}

        <div className="card-inner">
          {currentItems.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={tableData.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            <div className="text-center">
              <span className="text-silent">No data found</span>
            </div>
          )}
        </div>
      </DataTable>
    </>
  );
};

export default TableList;
