import React, { useEffect } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import HeaderSearch from "../header-search/HeaderSearch";
import ChatDropdown from "./dropdown/chat/Chat";

import { useTheme, useThemeUpdate } from '../provider/Theme';
import { useDispatch } from "react-redux";
import { success_logged_in_cred } from "../../reducers/dashboard_reducers/auth/logged_in_cred_Slice";

const Header = ({ fixed, className, ...props }) => {

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });


  const dispatch = useDispatch();
  // Saving Local Storage Data into a state
  useEffect(() => {
    if (localStorage.getItem("log_cred"))
    {
      dispatch(success_logged_in_cred({
        user_id: JSON.parse(localStorage.getItem('log_cred')).user_id,
        first_name: JSON.parse(localStorage.getItem('log_cred')).first_name,
        last_name: JSON.parse(localStorage.getItem('log_cred')).surname,
        email: JSON.parse(localStorage.getItem('log_cred')).email_address,
        username: JSON.parse(localStorage.getItem('log_cred')).username,
        department: JSON.parse(localStorage.getItem('log_cred')).department_name,
        role_name: JSON.parse(localStorage.getItem('log_cred')).role_name,
        role_id: JSON.parse(localStorage.getItem('log_cred')).role_id,
        account_type_id: JSON.parse(localStorage.getItem('log_cred')).account_type_id,
      }));
    }
  }, []);

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ms-n1"
              icon="menu"
              click={themeUpdate.sidebarVisibility}
            />
          </div>
          <div className="nk-header-brand d-xl-none">
            <Logo />
          </div>
          {/* <div className="nk-header-search ms-3 ms-xl-0">
            <HeaderSearch />
          </div> */}
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {/* <li className="chats-dropdown hide-mb-xs" onClick={themeUpdate.sidebarHide}>
                <ChatDropdown />
              </li>
              <li className="notification-dropdown me-n1" onClick={themeUpdate.sidebarHide}>
                <Notification />
              </li> */}
              <li className="user-dropdown" onClick={themeUpdate.sidebarHide}>
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
