import { useEffect, useState } from "react";
import ListText from "../../../text/ListText";
import * as app_consts from "../../../../../../constants/react_app_consts";

// Preview Create User
export const CreateUserPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="First Name" value={data.first_name || "--"} />
          <ListText
            dataItem="data-item mySmall"
            label="Middle Name (Optional)"
            value={data.middle_name ? data.middle_name : "--"}
          />
          <ListText dataItem="data-item mySmall" label="Surname" value={data?.surname || "--"} />
          <ListText dataItem="data-item mySmall" label="Email Address" value={data?.email_address || "--"} />
          <ListText dataItem="data-item mySmall" label="Phone Number" value={data?.phone_number || "--"} />
          <ListText dataItem="data-item mySmall" label="Address" value={data?.address || "--"} />
          <ListText dataItem="data-item mySmall" label="ID Number" value={data?.id_number || "--"} />
          <ListText dataItem="data-item mySmall" label="TPIN" value={data?.tpin || "--"} />
        </>
      )}
    </>
  );
};

// Preview Create Truck
export const CreateTruckPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Truck Reg No" value={data.truck_license_plate} />
          <ListText dataItem="data-item mySmall" label="Ownership Status" value={data.ownership_status} />
        </>
      )}
    </>
  );
};

// Preview Create Trailer
export const CreateTrailerPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Trailer License Plate" value={data.trailer_plate} />
          <ListText dataItem="data-item mySmall" label="Ownership Status" value={data.ownership_status} />
        </>
      )}
    </>
  );
};

// Preview Add Branch Location
export const CreateBranchLocationPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Branch Area" value={data.branch_area} />
          <ListText
            dataItem="data-item mySmall"
            label="Bracnch Physical Address"
            value={data.branch_physical_address}
          />
          <ListText dataItem="data-item mySmall" label="Bracnch Mobile Number" value={data.branch_mobile_number} />
          <ListText dataItem="data-item mySmall" label="Branch Province Name" value={data.province_name} />
        </>
      )}
    </>
  );
};

// Preview Creaet WeighBridge Ticket
export const CreatePhysicalWeighBridgeTicketPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Consigner" value={data.user_account_label} />
          <ListText dataItem="data-item mySmall" label="Branch Location" value={data.branch_location_name} />
          <ListText dataItem="data-item mySmall" label="Ticket Type" value={data.ticket_type} />
          <ListText dataItem="data-item mySmall" label="Truck Driver" value={data.transporter_name} />
          <ListText dataItem="data-item mySmall" label="Truck Driver" value={data.truck_driver_name} />
          <ListText dataItem="data-item mySmall" label="Truck Reg No" value={data.truck_licence_plate} />
          <ListText dataItem="data-item mySmall" label="Gross Weight" value={`${data.first_weight} MT`} />
          <ListText dataItem="data-item mySmall" label="Trailer Reg 1" value={data.trailer_one || "--"} />
          <ListText dataItem="data-item mySmall" label="Trailer Reg 2" value={data.trailer_two || "--"} />
          <ListText dataItem="data-item mySmall" label="Ticket Comments" value={data.comments || "--"} />
        </>
      )}
    </>
  );
};


// Preview Creaet WeighBridge Ticket
export const BookPhysicalGoodsRecievedIntoStoragePrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);

  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Gross Weight" value={data.first_weight ? `${data.first_weight} MT` : "--"} />
          <ListText dataItem="data-item mySmall" label="Tare Weight" value={data?.second_weight ? `${data.second_weight} MT` : "--"} />
          <ListText dataItem="data-item mySmall" label="Net Weight" value={data?.net_weight ? `${data.net_weight} MT` : "--"} />
          <ListText dataItem="data-item mySmall" label="Total Product Weight (MT)" value={data?.total_mt_capacity?.toFixed(2)} />
        </>
      )}
    </>
  );
};

// Preview Creaet WeighBridge Ticket
export const CreatePhysicaDeliveryNotelWeighBridgeTicketPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Consigner" value={data.user_account_label} />
          <ListText dataItem="data-item mySmall" label="Ticket Type" value={data.ticket_type} />
          {
            data.consigner_type_id === app_consts.UAG_CONSIGNER_TYPE ?
              <>
                <ListText dataItem="data-item mySmall" label="Branch Ref No" value={data.property_ref_code} />
                <ListText dataItem="data-item mySmall" label="Branch Area" value={data.property_name} />
                <ListText dataItem="data-item mySmall" label="Branch Physical Address" value={data.property_physical_address} />
              </>
              :
              <>
                <ListText dataItem="data-item mySmall" label="Property Number" value={data.property_ref_code} />
                <ListText dataItem="data-item mySmall" label="Property Name" value={data.property_name} />
                <ListText dataItem="data-item mySmall" label="Property Physical Address" value={data.property_physical_address} />
              </>
          }
          <ListText dataItem="data-item mySmall" label="Truck Driver" value={data.truck_driver} />
          <ListText dataItem="data-item mySmall" label="Truck Reg No" value={data.truck_license_plate} />
          <ListText dataItem="data-item mySmall" label="Gross Weight" value={`${data.first_weight} MT`} />
          <ListText dataItem="data-item mySmall" label="Trailer Reg 1" value={data.trailer_1 || "--"} />
          <ListText dataItem="data-item mySmall" label="Trailer Reg 2" value={data.trailer_2 || "--"} />
        </>
      )}
    </>
  );
};

// Preview Final Details Delivery NOte
export const FinalDeliveryNoteDetailsPrev = ({ prevData }) => {
  const [data, setData] = useState(prevData);

  // UseEffect for Data
  useEffect(() => {
    setData(prevData);
  }, [prevData]);
  return (
    <>
      {data && (
        <>
          <ListText dataItem="data-item mySmall" label="Tare Weight (MT)" value={data.first_weight} />
          <ListText dataItem="data-item mySmall" label="Gross Weight (MT)" value={data.second_weight} />
          <ListText dataItem="data-item mySmall" label="Net Weight (MT)" value={data.net_weight} />
        </>
      )}
    </>
  );
};