import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import PageHeading from "../components/page_heading/PageHeading";
import WizardComp from "../components/wizard/WizardComp";
import * as app_consts from "../../../constants/react_app_consts";
import { useParams } from "react-router";

const CreateGoodsReceived = ({ page_type }) => {

  const { goods_received_ref_number } = useParams();
  const [page, setPage] = useState(page_type);

  return (
    <>
      <Head title={`${goods_received_ref_number ? "Continue Processing" : "Create"} Physical Goods Received Ticket`} />
      <Content>
        <PageHeading
          title={page === app_consts.CREATE_PHYSICAL_GOODS_RECEIVED_TICKET ? `${goods_received_ref_number ? "Continue Processing" : "Create"} Physical Goods Received Ticket` : page === app_consts.CREATE_VIRTUAL_GOODS_RECEIVED_TICKET ? `${goods_received_ref_number ? "Continue Processing" : "Create"} Virtual Goods Received Ticket` : ""}
          title_addition={goods_received_ref_number}
          description={page === app_consts.CREATE_PHYSICAL_GOODS_RECEIVED_TICKET ? "Process a Physical Goods Received Ticket from your Designated Warehouse." : page === app_consts.CREATE_VIRTUAL_GOODS_RECEIVED_TICKET ? "Process a Virtual Goods Received Ticket into Virtual Inventory." : ""}
          backToText={`${goods_received_ref_number ? `${goods_received_ref_number} Ticket Details` : "Goods Received Tickets"}`}
          backToLink={`/dashboard/goods_received${goods_received_ref_number ? `/${goods_received_ref_number}` : ""}`}
        />
        {/* Multi-Step Form */}
        {
          <WizardComp type={page} />
        }
      </Content>
    </>
  );
};

export default CreateGoodsReceived;
