import React, { useEffect, useState } from "react";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import TextInput from "../../../../../components/inputs/TextInput";
import ListText from "../../../text/ListText";
import { Link } from "react-router-dom";
import { employee_dashboard_actions_helper } from "../../../../../../actions/actions_helper";
import {
  fail_product_type_list,
  loading_product_type_list,
  reset_product_type_list,
  success_product_type_list,
} from "../../../../../../reducers/dashboard_reducers/products/product_type_list_Slice";
import {
  ADD_PRODUCT_ACCESS_DENIED_MSG,
  ADD_PRODUCT_ERR_MSG,
  ADD_PRODUCT_SUCCESS_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG,
  RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG,
  RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG,
  RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG,
  RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG,
} from "../../../../../../constants/success_error_messages_consts";
import {
  ADD_PRODUCT,
  RETRIEVE_ACTIVE_PRODUCT_TYPES_LIST,
  RETRIEVE_PRODUCT_TYPES_LIST,
  RETRIEVE_UNIT_MEASUREMENT_LIST,
} from "../../../../../../api/api_data";
import { useSelector } from "react-redux";
import { messageToast } from "../../../../../../utils/Utils";
import { useDispatch } from "react-redux";
import * as app_consts from "../../../../../../constants/react_app_consts";
import {
  fail_add_product,
  loading_add_product,
  reset_add_product,
  success_add_product,
} from "../../../../../../reducers/dashboard_reducers/products/add_product_Slice";
import { fail_unit_measurement_list, loading_unit_measurement_list, reset_unit_measurement_list, success_unit_measurement_list } from "../../../../../../reducers/dashboard_reducers/unit_measurement/unit_measurements_list_Slice";
import ReadOnlyTable from "../../../table/ReadOnlyTable";

const insitialState = { product_name: "", product_type_id: "", product_type_name: "", uom_details: "" };
const previewState = { product_name: "", product_type_name: "" };

const AddProductModal = ({ successFunction, openModal, setModalLoading }) => {
  const [formData, setFormData] = useState(insitialState);
  const [previewData, setPreviewData] = useState(previewState);
  const [step, setStep] = useState(1);
  const [errorVal, setError] = useState("");
  const [listLoadnig, setListLoading] = useState(false);
  const dispatch = useDispatch();
  const [productTypeList, setProductTypeList] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [unit_measurement_loading, setUnitMeasurementLoading] = useState(false);
  const [unit_measurement_data, setUnitMeasurementData] = useState([]);
  const [selectedUOM, setSelectedUOM] = useState([]);
  const [edit_um, setEditUM] = useState(false);
  const [edit_um_value, setEditUMValue] = useState(false);



  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    setFormData({ ...formData, ["uom_details"]: selectedUOM });
  }, [selectedUOM]);

  // UseEffect to Listen to Modal Opening
  useEffect(() => {
    if (openModal)
    {
      // Fetch Product Type List
      retrieve_product_type_list();
      retrieve_unit_measurements_list();
    }
  }, [openModal]);

  // Retrieve Product Type List
  const retrieve_product_type_list = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_product_type_list,
        success_product_type_list,
        fail_product_type_list,
        RETRIEVE_PRODUCT_TYPES_LIST_ERR_MSG,
        RETRIEVE_PRODUCT_TYPES_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACTIVE_PRODUCT_TYPES_LIST,
        null,
        RETRIEVE_PRODUCT_TYPES_LIST_ACCESS_DENIED_MSG,
        app_consts.MAP,
        app_consts.PRODUCT_TYPE_MAP
      )
    );
  };

  //Listen to State of Product Type List
  const product_type_list = useSelector((state) => state.product_type_list.product_type_list);
  //
  useEffect(() => {
    if (product_type_list.status !== app_consts.IDLE)
    {
      if (product_type_list.status === app_consts.LOADING)
      {
        setListLoading(true);
      } else
      {
        setListLoading(false);
        if (product_type_list.status === app_consts.FAIL)
        {
          messageToast(product_type_list.message, app_consts.FAIL);
        } else if (product_type_list.status === app_consts.SUCCESS)
        {
          setProductTypeList(product_type_list.data);
        }
        dispatch(reset_product_type_list());
      }
    }
  }, [product_type_list]);

  //   Refresh Product Type List
  const refreshList = () => {
    retrieve_product_type_list();
  };

  // Handle Preview
  const handlePreview = () => {
    setError("");
    setStep(2);
  };

  const handleSubmit = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_add_product,
        success_add_product,
        fail_add_product,
        ADD_PRODUCT_ERR_MSG,
        ADD_PRODUCT_SUCCESS_MSG,
        app_consts.POST,
        ADD_PRODUCT,
        formData,
        ADD_PRODUCT_ACCESS_DENIED_MSG
      )
    );
  };

  // Listen to Add Product State
  const add_product = useSelector((state) => state.add_product.add_product);
  // UseEffect
  useEffect(() => {
    if (add_product.status !== app_consts.IDLE)
    {
      if (add_product.status === app_consts.LOADING)
      {
        setAddLoading(true);
        setModalLoading(true);
      } else
      {
        setAddLoading(false);
        setModalLoading(false);
        if (add_product.status === app_consts.FAIL)
        {
          setError(add_product.message);
        } else if (add_product.status === app_consts.SUCCESS)
        {
          messageToast(`${formData.product_name} has been Successfully Added.`, app_consts.SUCCESS);
          // Close Modal and Other Embedded Functions if Need Be
          successFunction();
        }
        dispatch(reset_add_product());
      }
    }
  }, [add_product]);

  const retrieve_unit_measurements_list = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_unit_measurement_list,
        success_unit_measurement_list,
        fail_unit_measurement_list,
        RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG,
        RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_UNIT_MEASUREMENT_LIST,
        null,
        RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  //Listen to State of Unit Measurement List
  const unit_measurement_list = useSelector((state) => state.unit_measurement_list.unit_measurement_list);

  useEffect(() => {
    if (unit_measurement_list.status !== app_consts.IDLE)
    {
      if (unit_measurement_list.status === app_consts.LOADING)
      {
        setUnitMeasurementLoading(true);
      } else
      {
        setUnitMeasurementLoading(false);
        if (unit_measurement_list.status === app_consts.FAIL)
        {
          messageToast(unit_measurement_list.message, app_consts.FAIL);
        } else if (unit_measurement_list.status === app_consts.SUCCESS)
        {
          setUnitMeasurementData(unit_measurement_list.data);
        }
        dispatch(reset_unit_measurement_list());
      }
    }
  }, [unit_measurement_list]);

  const handleCheckboxChange = (unit, isChecked) => {
    setSelectedUOM((prevSelectedUOM) => {
      const updatedSelectedUOM = { ...prevSelectedUOM };
      if (isChecked)
      {
        // Add the unit object using its ID as the key
        updatedSelectedUOM[unit.unit_measurement_id] = [];
      } else
      {
        // Remove the unit object if unchecked
        delete updatedSelectedUOM[unit.unit_measurement_id];
      }
      return updatedSelectedUOM;
    });
  };

  const return_uom_from_uom_id = (uom_id) => {
    const filter = unit_measurement_data?.find((uom) => `${uom.unit_measurement_id}` === `${uom_id}`);
    return filter?.unit_measurement_full_name;
  };

  const handleInputChange = (key, field, value) => {
    setInputValues((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [field]: value,
      },
    }));
  };

  const handleAddOrUpdate = (key, type, modify_packet_size = null) => {
    const { packet_size, measurement_in_kilos } = inputValues[key] || {};

    if (type === "add")
    {
      if (!packet_size || !measurement_in_kilos)
      {
        messageToast("Both Packet Size and Measurement in KGs are required.", app_consts.FAIL);
        return;
      }

      setSelectedUOM((prevState) => {
        const currentArray = prevState[key] || [];
        const isDuplicate = currentArray.some((uom) => uom.packet_size === packet_size);

        if (isDuplicate)
        {
          messageToast(`Duplicate packet size: ${packet_size}`, app_consts.FAIL);
          return prevState; // Avoid adding duplicates
        }
        else
        {
          // Clear input values for the current key only
          setInputValues((prev) => ({
            ...prev,
            [key]: { packet_size: "", measurement_in_kilos: "", key: "" },
          }));

          return {
            ...prevState,
            [key]: [...currentArray, { packet_size, measurement_in_kilos, key: key }],
          };
        }

      });

    }

    if (type === "edit") 
    {
      if (!packet_size || !measurement_in_kilos)
      {
        messageToast("Both Packet Size and Measurement in KGs are required.", app_consts.FAIL);
        return;
      }

      setSelectedUOM((prevState) => {
        const currentArray = prevState[key] || [];

        // Check if the packet_size already exists in the array
        const isDuplicate = currentArray.some((uom) => uom.packet_size === packet_size);

        if (isDuplicate) 
        {
          messageToast(`Duplicate packet size: ${packet_size}.`, app_consts.FAIL);
          return prevState; // Avoid unnecessary updates
        }
        else
        {

          // Find and update the existing entry
          const updatedArray = currentArray.map((uom) =>
            uom.packet_size === edit_um_value
              ? { packet_size, measurement_in_kilos, key } // Update all fields
              : uom
          );

          // Debugging logs

          // Clear input values for the current key only
          setInputValues((prev) => ({
            ...prev,
            [key]: { packet_size: "", measurement_in_kilos: "", key: "" },
          }));
          setEditUMValue("");
          setEditUM(false);
          messageToast(`Packet size ${edit_um_value} Successfully Updated to ${packet_size}.`, app_consts.SUCCESS);

          return {
            ...prevState,
            [key]: updatedArray, // Update the specific key with the new array
          };
        }
      });
    }

    if (type === "delete")
    {
      setSelectedUOM((prevState) => {
        const currentArray = prevState[key] || [];
        const updatedArray = currentArray.filter((uom) => uom.packet_size !== modify_packet_size);

        if (updatedArray.length === 0)
        {
          const { [key]: _, ...newState } = prevState; // Remove the key
          return newState;
        }

        return {
          ...prevState,
          [key]: updatedArray,
        };
      });
    }
  };

  const editUMToggle = ({ key, packet_size, measurement_in_kilos }) => {
    setEditUM(true);
    setEditUMValue(packet_size);
    handleInputChange(key, "packet_size", packet_size);
    handleInputChange(key, "measurement_in_kilos", measurement_in_kilos);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <h5 className="card-title text-start w-100">Add Product Form</h5>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row className="g-4">
          <TextInput
            label="Product Name"
            required
            type="text"
            id_val="product_name"
            maxlength="100"
            value={formData.product_name}
            colSize="6"
            name="product_name"
            handleChange={(e) => {
              setFormData({ ...formData, ["product_name"]: e.target.value });
              setPreviewData({ ...previewData, ["product_name"]: e.target.value });
            }}
          />
          <Col size={5}>
            <div className="form-group">
              <label className="form-label mb-1 text-md" htmlFor="username">
                Product Type
              </label>
              <div className="form-control-wrap align-items-center w-100 d-flex flex-row mb-2 justify-between">
                <div className="w-85">
                  <RSelect
                    options={productTypeList}
                    className="w-100"
                    placeholder="Search"
                    id="username"
                    onChange={(e) => {
                      setFormData({ ...formData, ["product_type_id"]: e.value });
                      setPreviewData({ ...previewData, ["product_type_name"]: e.label });
                    }}
                    isDisabled={listLoadnig}
                  />
                </div>
                <div className="w-5">
                  {
                    // Waiting for List to Load
                    listLoadnig ? (
                      <Spinner className="fs-18px" />
                    ) : (
                      <Link
                        to="#reload"
                        onClick={(e) => {
                          e.preventDefault();
                          refreshList();
                        }}
                      >
                        <Icon name="reload-alt btn-icon fs-22px"></Icon>
                      </Link>
                    )
                  }
                </div>
              </div>
            </div>
          </Col>
          <div className="form-label-group">
            <label className="form-label text-soft fs-18px">UOM Details</label>
          </div>
          {
            unit_measurement_data?.map((unit, index) => {
              return (
                <>
                  <Col sm="6" md="3" key={index}>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`${index}`}
                        checked={selectedUOM[unit.unit_measurement_id]}
                        onChange={(e) =>
                          handleCheckboxChange(unit, e.target.checked)
                        }
                      />
                      <label className="custom-control-label" htmlFor={index}>
                        {unit?.unit_measurement_full_name}
                      </label>
                    </div>
                  </Col>
                </>
              );
            })
          }
          {/* Editable Table */}
          {Object?.entries(selectedUOM).map(([key, value]) => (
            <>
              <div className="my-3" key={key}>
                <p className="fs-18px fw-bold" style={{ marginBottom: 4 }}>{return_uom_from_uom_id(key)}</p>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                  }}
                >
                  <div style={{ marginBottom: 20, alignItems: "center" }}>
                    <Row className="g-4">
                      <TextInput
                        label="Packet Size"
                        required
                        type="text"
                        id_val="packet_size"
                        maxlength="100"
                        colSize="4"
                        value={inputValues[key]?.packet_size || value.packet_size || ""}
                        handleChange={(e) =>
                          handleInputChange(key, "packet_size", e.target.value)
                        }
                      />
                      <TextInput
                        label="Measurement in KGs"
                        required
                        type="number"
                        id_val="measurement_in_kilos"
                        maxlength="100"
                        colSize="4"
                        value={inputValues[key]?.measurement_in_kilos || value.measurement_in_kilos || ""}
                        handleChange={(e) =>
                          handleInputChange(key, "measurement_in_kilos", e.target.value)
                        }
                      />
                    </Row>
                  </div>
                  <div style={{ marginBottom: 20, alignItems: "center" }}>
                    <Row className="g-4">
                      <Col size={4}>
                        <Button color="gray"
                          onClick={(e) => { e.preventDefault(); if (edit_um) { handleAddOrUpdate(key, "edit"); } else { handleAddOrUpdate(key, "add"); setEditUMValue(""); } }}
                        >
                          {edit_um ? "Edit Packet Size" : "Add Packet Size"}
                        </Button>
                      </Col>
                      {
                        edit_um && (
                          <>
                            <Col size={3}>
                              <Button color="light"
                                onClick={(e) => {
                                  e.preventDefault(); setEditUM(false); setInputValues((prev) => ({
                                    ...prev,
                                    [key]: { packet_size: "", measurement_in_kilos: "", key: "" },
                                  }));
                                }}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </>
                        )
                      }
                    </Row>
                  </div>
                </form>
                <ReadOnlyTable tableType={app_consts.ADD_EDIT_DELETE_UM_TABLE} data={value} additionalData={{ um: unit_measurement_data }} action_function={handleAddOrUpdate} setSelectedData={editUMToggle} />
              </div>
            </>
          ))}
          {errorVal && (
            <div className="mb-3 w-100">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <Col xl="12" className="d-flex justify-content-end px-5">
            <Button onClick={handleSubmit} disabled={
              addLoading ||
              !(formData?.product_name && formData?.product_type_id) ||
              Object.keys(selectedUOM || {}).length === 0 ||
              Object.values(selectedUOM || {}).length === 0 ||
              Object.values(selectedUOM || {})?.some(value => !value)
            } color="primary">
              {addLoading ? <Spinner size="sm" color="light" /> : "Add Product Details"}
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default AddProductModal;