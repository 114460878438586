import React, { useEffect, useState } from 'react';
import { Icon } from '../../../../components/Component';
import { Card } from 'reactstrap';
import { FaBoxOpen, FaCheckCircle, FaFileInvoice, FaTruckLoading, FaUser } from 'react-icons/fa';

const InventoryProcessingStats = ({ data }) => {

 const [page_data, setPage_data] = useState(data);

 useEffect(() => {
  setPage_data(data);
 }, [data]);

 return (
  <>
   <Card className="h-100">
    <div className="card-inner">
     <div className="card-title-group mb-2">
      <div className="card-title">
       <h6 className="title">Order and Delivery Records</h6>
      </div>
     </div>
     <ul className="nk-store-statistics">
      <li className="item">
       <div className="info">
        <div className="fs-18px">Customers/Clients</div>
        <div className="count">{page_data?.total_clients || "--"}</div>
       </div>
       <FaUser className='fs-22px bg-purple-dim' />
      </li>
      <li className="item">
       <div className="info">
        <div className="fs-18px">Goods Received</div>
        <div className="count">{page_data?.total_loading_orders || "--"}</div>
       </div>
       <FaBoxOpen className='fs-22px bg-primary-dim' />
      </li>
      <li className="item">
       <div className="info">
        <div className="fs-18px">Loading Orders</div>
        <div className="count">{page_data?.total_clients || "--"}</div>
       </div>
       <FaTruckLoading className='fs-22px bg-info-dim' />
      </li>
      <li className="item">
       <div className="info">
        <div className="fs-18px">Delivery Notes</div>
        <div className="count">{page_data?.total_delivery_notes || "--"}</div>
       </div>
       <FaFileInvoice className='fs-22px bg-pink-dim' />
      </li>
      <li className="item">
       <div className="info">
        <div className="fs-18px">Confirmed Delivery Notes</div>
        <div className="count">{page_data?.total_confirmed_delivery_notes || "--"}</div>
       </div>
       <FaCheckCircle className='fs-22px bg-purple-dim' />
      </li>
     </ul>
    </div>
   </Card>
  </>
 );
};

export default InventoryProcessingStats;