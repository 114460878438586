import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import TablePageHeading from "../components/page_heading/TablePageHeading";
import { useSelector } from "react-redux";
import * as app_consts from "../../../constants/react_app_consts";
import { useDispatch } from "react-redux";
import { fail_retrieve_goods_received_weigh_bridge_tickets, loading_retrieve_goods_received_weigh_bridge_tickets, reset_retrieve_goods_received_weigh_bridge_tickets, success_retrieve_goods_received_weigh_bridge_tickets } from "../../../reducers/dashboard_reducers/warehouse/retrieve_goods_received_weigh_bridge_tickets_Slice";
import { RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_ACCESS_DENIED_MSG, RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_ERR_MSG, RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_SUCCESS_MSG } from "../../../constants/success_error_messages_consts";
import { RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST_API } from "../../../api/api_data";
import { messageToast } from "../../../utils/Utils";
import TableList from "../components/table/TableList";
import { Block } from "../../../components/Component";
import CardTabs from "../components/cards/CardTabs";
import ModalDetails from "../components/modal/modal_details/ModalDetails";
import { findModuleAction } from "../../../utils/functionalUtils";
import { employee_dashboard_actions_helper } from "../../../actions/actions_helper";
import { BiErrorCircle } from "react-icons/bi";
import { Spinner } from "reactstrap";
import moment from "moment";

const initial_actions_data = {
  create_goods_received_weigh_bridge_ticket: false,
};
const type = app_consts.GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST;

const GoodsReceivedWeighBridgeTicketList = () => {
  const [page_data, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [componentData, setComponentData] = useState(initial_actions_data);
  const [activeCardTab, setActiveCardTab] = useState("All");
  const [dataFetched, setDataFetched] = useState(false);
  const [fully_processed_data, setFullyProcessedData] = useState([]);
  const [partially_processed_data, setPartiallyProcessedData] = useState([]);
  const [openModalDetails, setOpendModalDetails] = useState(false);
  const [detail, setDetail] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const [current_page_data, setCurrentPageData] = useState([]);
  const [current_fully_processed, setCurrentFullyProcessed] = useState([]);
  const [current_partially_processed, setCurrentPartiallyProcessed] = useState([]);

  const search_placeholder = "Search By GRN No, Client Name, Client ID, Ticket Type, Branch Location, Date Received or Status ....";

  useEffect(() => {
    retrieve_goods_received_weigh_bridge_tickets_func();
  }, []);

  // Refresh List Function
  const refreshList = () => {
    setError("");
    // Retrieve Weigh Bridge Tickets list
    //   retrieve_user_list_func();
    retrieve_goods_received_weigh_bridge_tickets_func();
    setPageData([]);
  };

  // Retrieve Users
  const retrieve_goods_received_weigh_bridge_tickets_func = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_retrieve_goods_received_weigh_bridge_tickets,
        success_retrieve_goods_received_weigh_bridge_tickets,
        fail_retrieve_goods_received_weigh_bridge_tickets,
        RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_ERR_MSG,
        RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_LIST_API,
        null,
        RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_ACCESS_DENIED_MSG
      )
    );
  };

  // Weigh Bridge Ticket List Listener
  const goods_received_weigh_bridge_tickets = useSelector((state) => state.retrieve_goods_received_weigh_bridge_tickets.retrieve_goods_received_weigh_bridge_tickets);
  // 
  useEffect(() => {
    if (goods_received_weigh_bridge_tickets.status !== app_consts.IDLE)
    {
      if (goods_received_weigh_bridge_tickets.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        if (goods_received_weigh_bridge_tickets.status === app_consts.FAIL)
        {
          setError(goods_received_weigh_bridge_tickets.message || RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_ERR_MSG);
          messageToast(RETRIEVE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET_ERR_MSG, app_consts.FAIL);
        } else if (goods_received_weigh_bridge_tickets.status === app_consts.SUCCESS)
        {
          setPageData(goods_received_weigh_bridge_tickets.data);
          setCurrentPageData(goods_received_weigh_bridge_tickets.data);
          setDataFetched(true);
        }
        setLoading(false);
        dispatch(reset_retrieve_goods_received_weigh_bridge_tickets());
      }
    }
  }, [goods_received_weigh_bridge_tickets]);

  // UseEffect to Filter Page Data
  useEffect(() => {
    setData(page_data);
  }, [activeCardTab]);

  // Retrieve Module Actions Data
  const action_roles = useSelector((state) => state.auth_routes.auth_routes);
  // 
  useEffect(() => {
    if (action_roles.status === app_consts.SUCCESS)
    {
      if (action_roles.data)
      {
        // Set Action Roles to Warehouse Managment 
        const warehouse_management = action_roles?.data?.find((module) => module.module_name === app_consts.WAREHOUSE_MANAGEMENT);
        setComponentData(warehouse_management);
      }
    }
  }, [action_roles]);

  // Card Tabs
  const tabs = [
    {
      tabName: "All"
    },
    {
      tabName: "Fully Processed"
    },
    {
      tabName: "Pending"
    },
  ];

  // UseEffect for Specific Data
  useEffect(() => {
    if (selectedData)
    {
      setDetail(filterSpecificData(selectedData));
    }
  }, [selectedData]);

  useEffect(() => {
    // Set Data From Curren Data
    if (page_data)
    {
      setData(page_data);
    }

  }, [page_data, activeCardTab]);

  const setData = (all_page_data) => {
    const complete_data = page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE);

    const uncomplete_data = all_page_data?.filter((complete) => complete.weigh_bridge_ticket_status.weigh_bridge_ticket_status_id !== app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE);

    setFullyProcessedData(complete_data);
    setCurrentFullyProcessed(complete_data);
    setPartiallyProcessedData(uncomplete_data);
    setCurrentPartiallyProcessed(uncomplete_data);
  };

  // Filter Specific User
  const filterSpecificData = (selected_user) => {
    const filtereredData = page_data?.filter((data) => data.goods_received_ref_number === selected_user);
    return filtereredData[0];
  };

  const searchFunction = (searchItem) => {
    if (searchItem)
    {
      let filter_data = [];

      switch (activeCardTab)
      {
        case "All":
          filter_data = page_data || [];
          break;
        case "Fully Processed":
          filter_data = fully_processed_data || [];
          break;
        case "Pending":
          filter_data = partially_processed_data || [];
          break;
        default:
          filter_data = [];
      }

      const search_data = filter_data?.filter((item) => {
        return (
          item?.goods_received?.goods_received_ref_number.toLowerCase().includes(searchItem?.toLowerCase()) ||
          item?.user_details?.name.toLowerCase().includes(searchItem?.toLowerCase()) ||
          item?.user_account?.unique_identification_number.toLowerCase().includes(searchItem?.toLowerCase()) ||
          item?.weigh_bridge_ticket_type?.weigh_bridge_ticket_type.toLowerCase().includes(searchItem?.toLowerCase()) ||
          item?.weigh_bridge_ticket?.truck_licence_plate.toLowerCase().includes(searchItem?.toLowerCase()) ||
          (item?.weigh_bridge_ticket_status?.weigh_bridge_ticket_status
            ?.replace(/_/g, " ")
            ?.toLowerCase()
            ?.includes(searchItem?.toLowerCase())) ||
          (item?.goods_received?.inserted_at
            ? moment(item?.goods_received?.inserted_at).format("YYYY-MM-DD h:mm:ss").toLowerCase().includes(searchItem?.toLowerCase())
            : false) // Ensure it doesn't throw errors
        );
      });


      if (activeCardTab === "All")
      {
        setCurrentPageData(search_data);
      } else if (activeCardTab === "Fully Processed")
      {
        setCurrentFullyProcessed(search_data);
      } else if (activeCardTab === "Pending")
      {
        setCurrentPartiallyProcessed(search_data);
      }
    }
    else
    {
      setActiveCardTab(activeCardTab);
      setData(page_data);
    }
  };

  return (
    <>
      <Head title="Goods Received Tickets List" />
      <Content>
        {/* Table Page Heading */}
        <TablePageHeading
          title="Goods Received Tickets List"
          description={`Total Goods Received Tickets: ${page_data.length}`}
          refresh={!loading}
          refreshFunc={refreshList}
          csv_export={!loading && !error}
          add_to_list={loading || error ? "" : findModuleAction(app_consts.CREATE_GOODS_RECEIVED_WEIGH_BRIDGE_TICKET, componentData) !== null}
          componentData={componentData}
          page_type={type}
          pageData={activeCardTab === "All" ? current_page_data : activeCardTab === "Fully Processed" ? current_fully_processed : current_partially_processed}
        />
        {
          loading ?
            <>
              <div className='p-5 d-flex align-center justify-center'>
                <Spinner />
              </div>
            </>
            :
            <>
              {
                error && error.length > 0 ?
                  <>
                    <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                      <BiErrorCircle className="text-danger fs-3" />
                      <p>{error}</p>
                    </div>
                  </>
                  :
                  <>
                    <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={!loading} />
                    {/* Table */}
                    <Block>
                      {
                        activeCardTab === "All" && (
                          <>
                            <TableList
                              // left_head_select
                              type={type}
                              data={current_page_data}
                              rowOptionsData={componentData}
                              // amendFunc={amend_Account_Status_modal}
                              setOpendModalDetails={setOpendModalDetails}
                              setSelectedData={setSelectedData}
                              searchFunction={searchFunction}
                              search_placeholder={search_placeholder}
                            />
                          </>
                        )
                      }
                      {
                        activeCardTab === "Fully Processed" && (
                          <>
                            <TableList
                              // left_head_select
                              type={type}
                              data={current_fully_processed}
                              rowOptionsData={componentData}
                              // amendFunc={amend_Account_Status_modal}
                              setOpendModalDetails={setOpendModalDetails}
                              setSelectedData={setSelectedData}
                              searchFunction={searchFunction}
                              search_placeholder={search_placeholder}
                            />
                          </>
                        )
                      }
                      {
                        activeCardTab === "Pending" && (
                          <>
                            <TableList
                              // left_head_select
                              type={type}
                              data={current_partially_processed}
                              rowOptionsData={componentData}
                              // amendFunc={amend_Account_Status_modal}
                              setOpendModalDetails={setOpendModalDetails}
                              setSelectedData={setSelectedData}
                              searchFunction={searchFunction}
                              search_placeholder={search_placeholder}
                            />
                          </>
                        )
                      }

                    </Block>
                    {/* Modal For Viewing User Data */}
                    <ModalDetails
                      modalOpen={openModalDetails}
                      setModalOpen={setOpendModalDetails}
                      modalTitle={`${detail && `${detail?.product_name}'s Details`}`}
                      modalType={type}
                      modalData={detail}
                    />
                  </>
              }
            </>
        }
      </Content>
    </>
  );
};

export default GoodsReceivedWeighBridgeTicketList;
