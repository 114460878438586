import React, { useEffect, useState } from 'react';
import { Alert, Spinner } from 'reactstrap';
import { Button, Col, Icon } from '../../../../../../components/Component';
import { Link } from 'react-router-dom';
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { fail_retrieve_employee_list, loading_retrieve_employee_list, reset_retrieve_employee_list, success_retrieve_employee_list } from '../../../../../../reducers/dashboard_reducers/user/retrieve_employee_list_Slice';
import { ADD_EMPLPOYEE_TO_BRANCH_LOCATION_ACCESS_DENIED_MSG, ADD_EMPLPOYEE_TO_BRANCH_LOCATION_ERR_MSG, ADD_EMPLPOYEE_TO_BRANCH_LOCATION_SUCCESS_MSG, RETRIEVE_EMPLOYEE_LIST_ACCESS_DENIED_MSG, RETRIEVE_EMPLOYEE_LIST_ERR_MSG, RETRIEVE_EMPLOYEE_LIST_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import * as app_consts from "../../../../../../constants/react_app_consts";
import { useSelector } from 'react-redux';
import { BiErrorCircle } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { ADD_EMPLOYEE_TO_BRANCH_LOCATION_API, RETRIEVE_EMPLOYEES_NOT_AT_SPECIFIC_BRANCH_API } from '../../../../../../api/api_data';
import TextInput from '../../../../../components/inputs/TextInput';
import ReadOnlyTable from '../../../table/ReadOnlyTable';
import { fail_add_employee_to_branch_location, loading_add_employee_to_branch_location, reset_add_employee_to_branch_location, success_add_employee_to_branch_location } from '../../../../../../reducers/dashboard_reducers/branch_locations/add_employee_to_branch_location_Slice';
import { messageToast } from '../../../../../../utils/Utils';

const AddEmployeeToBranch = ({ openModal, page_data, successFunction }) => {

  const [errorVal, setError] = useState("");
  const [employee_list_loading, setEmployeeListLoading] = useState(true);
  const [branch_location_id, setBranchLocationID] = useState(page_data);
  const [employee_list_data, setEmployeeListData] = useState([]);
  const [employee_list_error, setEmployeeListError] = useState("");
  const dispatch = useDispatch();
  const [onSearchText, setOnSearchText] = useState("");
  const [selected_data, setSelectedData] = useState([]);
  const [current_employee_list, setCurrentEmployeeList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openModal)
    {
      if (page_data)
      {
        refreshList(page_data);
      };
      setBranchLocationID(page_data);
    }
  }, [openModal, page_data]);

  const refreshList = (branch_location_id) => {
    // Retrieve Data
    dispatch(
      employee_dashboard_actions_helper(
        loading_retrieve_employee_list,
        success_retrieve_employee_list,
        fail_retrieve_employee_list,
        RETRIEVE_EMPLOYEE_LIST_ERR_MSG,
        RETRIEVE_EMPLOYEE_LIST_SUCCESS_MSG,
        app_consts.GET,
        `${RETRIEVE_EMPLOYEES_NOT_AT_SPECIFIC_BRANCH_API}/${branch_location_id}`,
        null,
        RETRIEVE_EMPLOYEE_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  // Read Employee List State
  const employee_list = useSelector((state) => state.retrieve_employee_list.retrieve_employee_list);

  useEffect(() => {
    if (employee_list.status !== app_consts.IDLE)
    {
      if (employee_list.status === app_consts.LOADING)
      {
        setEmployeeListLoading(true);
      } else
      {
        setEmployeeListLoading(false);
        if (employee_list.status === app_consts.FAIL)
        {
          setEmployeeListError(employee_list.message);
        } else if (employee_list.status === app_consts.SUCCESS)
        {
          setEmployeeListData(employee_list.data);
          setCurrentEmployeeList(employee_list.data);
        }
        dispatch(reset_retrieve_employee_list());
      }
    }
  }, [employee_list]);

  //function to declare the state change
  const onActionText = (e) => {
    if (selected_data) setSelectedData("");
    setOnSearchText(e);
  };

  //Function to Initiate Search
  //Changing state value when searching name
  useEffect(() => {
    if (onSearchText)
    {
      const filteredObject = employee_list_data?.filter((item) => {
        return (
          item?.user?.first_name.toLowerCase().includes(onSearchText?.toLowerCase()) ||
          item?.user?.surname.toLowerCase().includes(onSearchText?.toLowerCase()) ||
          item?.user?.phone_number.toLowerCase().includes(onSearchText?.toLowerCase()) ||
          item?.employee_department?.department_name.toLowerCase().includes(onSearchText?.toLowerCase()) ||
          item?.employee_role?.role_name.toLowerCase().includes(onSearchText?.toLowerCase())
        );
      });
      setCurrentEmployeeList([...filteredObject]);
    } else
    {
      setCurrentEmployeeList(employee_list_data);
      setOnSearchText("");
    }
  }, [onSearchText]);

  const handleSubmit = () => {
    setError("");
    // Run Code to Submit Data
    dispatch(
      employee_dashboard_actions_helper(
        loading_add_employee_to_branch_location,
        success_add_employee_to_branch_location,
        fail_add_employee_to_branch_location,
        ADD_EMPLPOYEE_TO_BRANCH_LOCATION_ERR_MSG,
        ADD_EMPLPOYEE_TO_BRANCH_LOCATION_SUCCESS_MSG,
        app_consts.POST,
        ADD_EMPLOYEE_TO_BRANCH_LOCATION_API,
        { employee_id: selected_data?.employee?.employee_id, branch_location_id: branch_location_id },
        ADD_EMPLPOYEE_TO_BRANCH_LOCATION_ACCESS_DENIED_MSG
      )
    );
  };

  // Read Employee List State
  const add_branch_listener = useSelector((state) => state.add_employee_to_branch_location.add_employee_to_branch_location);

  useEffect(() => {
    if (add_branch_listener.status !== app_consts.IDLE)
    {
      if (add_branch_listener.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (add_branch_listener.status === app_consts.FAIL)
        {
          setError(add_branch_listener.message);
          messageToast(add_branch_listener.message, app_consts.FAIL);
        } else if (add_branch_listener.status === app_consts.SUCCESS)
        {
          messageToast(add_branch_listener.message, app_consts.SUCCESS);
          successFunction();
        }
        dispatch(reset_add_employee_to_branch_location());
      }
    }
  }, [add_branch_listener]);

  return (
    <>
      {errorVal && (
        <div className="mb-3 w-100">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      {
        !employee_list_loading && (
          <>
            <div className="mt-2">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  refreshList(branch_location_id);
                }}
              >
                <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                  <span>Refresh List</span>
                </Button>
              </Link>
            </div>
          </>
        )
      }
      {
        employee_list_loading ?
          <>
            <div className='p-5 d-flex align-center justify-center flex flex-column'>
              <Spinner />
              <p className='mt-4 fs-18px'>Loading Employee List</p>
            </div>
          </>
          :
          <>
            {
              employee_list_error && employee_list_error.length > 0 ?
                <>
                  <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                    <BiErrorCircle className="text-danger fs-3" />
                    <p>{employee_list_error}</p>
                  </div>
                </>
                :
                <>
                  <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <Col>
                      <TextInput
                        parentClassName={"mt-4"}
                        label="Search for Employee"
                        type="text"
                        id_val="user_search"
                        value={onSearchText}
                        colSize="6"
                        name="user_search"
                        handleChange={(e) => onActionText(e.target.value)}
                      />
                    </Col>
                    <p style={{ marginTop: 20 }}>Select a Row to Proceed</p>
                    <div className="my-3">
                      <ReadOnlyTable data={current_employee_list} tableType={app_consts.ADD_EMPLOYEE_TO_BRANCH} setSelectedData={setSelectedData} page_size={5} />
                    </div>
                    <Col xl="12">
                      <div className="form-group mt-2">
                        <div className="form-control-wrap-flex-wrap justify-end">
                          {selected_data ? (
                            <Button color="primary" size="lg">
                              <span>Add Employee</span>
                            </Button>
                          ) : (
                            <Button disabled={loading} color="primary" size="lg">
                              <span>Add Employee</span>
                            </Button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </form>
                </>
            }
          </>

      }
    </>
  );
};

export default AddEmployeeToBranch;