import React from 'react';
import { Col } from 'reactstrap';

const TextInput = ({ disabled, label, required, id_val, name, handleChange, error_stat, err_Msg, onFocus, colSize, type, maxlength, minlength, max, value, min, onBlur, parentClassName }) => {
  return (
    <>
      <Col lg={colSize ? colSize : "6"} className={parentClassName}>
        <div className="form-group">
          {
            label && (
              <>
                <div className="form-label-group">
                  <label className="form-label" htmlFor={id_val}>
                    {label}{required && (<span className='text-danger pr-2'>{` *`}</span>)}
                  </label>
                </div>
              </>
            )
          }
          <div className="form-control-wrap">
            <input
              required={required}
              onChange={handleChange}
              type={type}
              id={id_val}
              max={type === "date" || type === "number" ? max : ""}
              className="form-control"
              name={name}
              min={type === "date" || type === "number" ? min : ""}
              value={value}
              maxLength={maxlength ? maxlength : ""}
              minLength={minlength ? minlength : ""}
              disabled={disabled}
              onFocus={onFocus}
              onBlur={onBlur} />
          </div>
          <span className="text-danger">{error_stat ? `${err_Msg} ` : ""}</span>
        </div>
      </Col>
    </>
  );
};

export default TextInput;