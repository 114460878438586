import React, { useEffect, useState } from 'react';
import { Alert, Card, CardTitle, Col, Row, Spinner } from 'reactstrap';
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { Button, Icon, RSelect } from '../../../../../../../../components/Component';
import TextInput from '../../../../../../../components/inputs/TextInput';
import { fail_unit_measurement_list, loading_unit_measurement_list, reset_unit_measurement_list, success_unit_measurement_list } from '../../../../../../../../reducers/dashboard_reducers/unit_measurement/unit_measurements_list_Slice';
import { BOOK_GOODS_RECEIVED_INT0_INVENTORY_ACCESS_DENIED_MSG, BOOK_GOODS_RECEIVED_INT0_INVENTORY_ERR_MSG, BOOK_GOODS_RECEIVED_INT0_INVENTORY_SUCCESS_MSG, RETRIEVE_UNIT_MEASUREMENTS_LIST_ACCESS_DENIED_MSG, RETRIEVE_UNIT_MEASUREMENTS_LIST_ERR_MSG, RETRIEVE_UNIT_MEASUREMENTS_LIST_SUCCESS_MSG, RETRIEVE_WAREHOUSE_STORAGE_LIST_ACCESS_DENIED_MSG, RETRIEVE_WAREHOUSE_STORAGE_LIST_ERR_MSG, RETRIEVE_WAREHOUSE_STORAGE_LIST_SUCCESS_MSG } from '../../../../../../../../constants/success_error_messages_consts';
import { BOOK_GOODS_RECEIVED_INTO_INVENTORY_API, RETRIEVE_SPECIFC_BRANCH_STORAGE_LIST_BY_STATUS_ID_API, RETRIEVE_UNIT_MEASUREMENTS_BY_STATUS_ENDPOINT } from '../../../../../../../../api/api_data';
import { employee_dashboard_actions_helper } from '../../../../../../../../actions/actions_helper';
import { useSelector } from 'react-redux';
import { messageToast } from '../../../../../../../../utils/Utils';
import { useDispatch } from 'react-redux';
import { fail_retrieve_warehouse_storages_list, loading_retrieve_warehouse_storages_list, reset_retrieve_warehouse_storages_list, success_retrieve_warehouse_storages_list } from '../../../../../../../../reducers/dashboard_reducers/warehouse/retrieve_warehouse_storages_list_Slice';
import ModalPreview from '../../../../../modal/modal_preview/ModalPreview';
import { fail_book_goods_to_inventory, loading_book_goods_to_inventory, reset_book_goods_to_inventory, success_book_goods_to_inventory } from '../../../../../../../../reducers/dashboard_reducers/warehouse/book_goods_to_inventory_Slice';

const initialState = { second_weight: "", weigh_bridge_ticket_number: "", total_mt_capacity: "" };
const initialPreviewState = { second_weight: "", weigh_bridge_ticket_number: "", net_weight: "", first_weight: "", net_weight: "", total_mt_capacity: "" };

const StorageDetailsStep = ({
    weighBridgeFormData,
    setWeighBridgeFormData,
    continue_processing_data,
    setContinueProcessingData,
    nextStep
}) => {

    const [errorVal, setErrorVal] = useState("");
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(weighBridgeFormData);
    const [stepData, setStepData] = useState(initialState);
    const [preview_step_data, setPreviewStepData] = useState(initialPreviewState);
    const dispatch = useDispatch();
    const [refData, setRefData] = useState([]);
    const [openPreview, setOpenPreview] = useState(false);

    useEffect(() => {
        setFormData(weighBridgeFormData);
        setRefData(continue_processing_data);
        const totalCapacitySum = weighBridgeFormData.goods_received_products?.reduce((sum, item) => {
            // Convert total_capacity_in_mt to a number and add to the sum
            return sum + parseFloat(item?.total_capacity_in_mt);
        }, 0);
        setStepData({ ...stepData, ["weigh_bridge_ticket_number"]: weighBridgeFormData?.weigh_bridge_ticket_number, ["total_mt_capacity"]: totalCapacitySum });
        setPreviewStepData({ ...preview_step_data, ["first_weight"]: stepData?.first_weight, ["total_mt_capacity"]: totalCapacitySum });

    }, [weighBridgeFormData, continue_processing_data]);

    useEffect(() => {
        setWeighBridgeFormData(formData);
    }, [formData]);

    const handleOnblur = (e) => {
        if (e.target.name === "second_weight")
        {
            if (e.target.value)
            {
                if (parseFloat(e.target.value) > parseFloat(stepData.first_weight))
                {
                    setStepData({ ...stepData, [e.target.name]: "", ["net_weight"]: "" });
                    setPreviewStepData({ ...preview_step_data, [e.target.name]: "", ["net_weight"]: "" });
                    messageToast("Tare Weight Cannot be more than Gross Weight", app_consts.FAIL);
                }
                else
                {
                    setStepData({ ...stepData, [e.target.name]: parseFloat(e.target.value).toFixed(2), ["net_weight"]: parseFloat(refData?.first_weight - e.target.value).toFixed(2) });
                    setPreviewStepData({ ...preview_step_data, [e.target.name]: parseFloat(e.target.value).toFixed(2), ["net_weight"]: parseFloat(stepData?.first_weight - e.target.value).toFixed(2) });
                }
            }
            else
            {

                setStepData({ ...stepData, [e.target.name]: "", ["net_weight"]: "" });
                setPreviewStepData({ ...preview_step_data, [e.target.name]: "", ["net_weight"]: "" });
            }
        }
        else
        {
            if (e.target.value)
            {
                setStepData({ ...stepData, [e.target.name]: parseFloat(e.target.value).toFixed(2) });
                setPreviewStepData({ ...preview_step_data, [e.target.name]: parseFloat(e.target.value).toFixed(2) });
            }
        }
    };

    // Handle Change
    const handleChange = (e) => {
        setStepData({ ...stepData, [e.target.name]: e.target.value });
        setPreviewStepData({ ...preview_step_data, [e.target.name]: e.target.value });
    };

    //Preview Details
    const handlePreview = () => {
        setErrorVal("");
        setOpenPreview(true);
    };

    const handleSubmit = () => {
        setErrorVal("");
        setOpenPreview(false);
        // Create Good Received Ticket
        dispatch(
            employee_dashboard_actions_helper(
                loading_book_goods_to_inventory,
                success_book_goods_to_inventory,
                fail_book_goods_to_inventory,
                BOOK_GOODS_RECEIVED_INT0_INVENTORY_ERR_MSG,
                BOOK_GOODS_RECEIVED_INT0_INVENTORY_SUCCESS_MSG,
                app_consts.POST,
                BOOK_GOODS_RECEIVED_INTO_INVENTORY_API,
                stepData,
                BOOK_GOODS_RECEIVED_INT0_INVENTORY_ACCESS_DENIED_MSG
            )
        );
    };
    // Listen to Booking State
    const book_goods_to_inventory = useSelector((state) => state.book_goods_to_inventory.book_goods_to_inventory);

    useEffect(() => {
        if (book_goods_to_inventory.status !== app_consts.IDLE)
        {
            if (book_goods_to_inventory.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (book_goods_to_inventory.status === app_consts.FAIL)
                {
                    setErrorVal(book_goods_to_inventory.message);
                    messageToast(book_goods_to_inventory.message, app_consts.FAIL);
                } else if (book_goods_to_inventory.status === app_consts.SUCCESS)
                {
                    setContinueProcessingData(book_goods_to_inventory.data);
                    messageToast(BOOK_GOODS_RECEIVED_INT0_INVENTORY_SUCCESS_MSG, app_consts.SUCCESS);
                    nextStep();
                }
                dispatch(reset_book_goods_to_inventory());
            }
        }
    }, [book_goods_to_inventory]);

    return (
        <>
            <Card className="card-inner">
                <CardTitle tag="h5">Finalizing GRN Details</CardTitle>
                <Col lg="12">
                    <div className="form-group">
                        {weighBridgeFormData.consigner_type_id === app_consts.UAG_CONSIGNER_TYPE ? (
                            <>
                                <div className="form-label-group">
                                    <label className="form-label text-soft">User Account ID :</label>
                                </div>
                                <div className="form-control-wrap">
                                    <label className="form-label text-primary">UAG</label>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="form-label-group">
                                    <label className="form-label text-soft">User Account ID :</label>
                                </div>
                                <div className="form-control-wrap">
                                    <label className="form-label text-primary">{weighBridgeFormData.user_account_label}</label>
                                </div>
                            </>
                        )}
                    </div>
                </Col>
                <hr />
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <Row className="g-4">
                        <div className="form-label-group">
                            <label className="form-label text-soft fs-22px">Finalizing Details</label>
                        </div>
                        <TextInput
                            parentClassName="mt-4"
                            colSize={6}
                            label="Gross Weight"
                            type="number"
                            id_val="first_weight"
                            value={stepData?.first_weight}
                            handleChange={handleChange}
                            name="first_weight"
                            onBlur={handleOnblur}
                        />
                        <TextInput
                            parentClassName="mt-4"
                            colSize={6}
                            label="Tare Weight"
                            type="number"
                            id_val="second_weight"
                            value={stepData.second_weight}
                            handleChange={handleChange}
                            name="second_weight"
                            onBlur={handleOnblur}
                            disabled={!stepData.first_weight}
                        />
                        <TextInput
                            parentClassName="mt-4"
                            colSize={6}
                            label="Net Weight"
                            type="number"
                            id_val="net_weight"
                            disabled
                            value={preview_step_data?.net_weight}
                            handleChange={handleChange}
                            name="net_weight"
                            onBlur={handleOnblur}
                        />
                        <TextInput
                            parentClassName="mt-4"
                            colSize={6}
                            label="Total Products Weight (MT)"
                            type="number"
                            id_val="total_mt_capacity"
                            value={preview_step_data.total_mt_capacity ? parseFloat(preview_step_data.total_mt_capacity).toFixed(2) : ""}
                            name="total_mt_capacity"
                            disabled
                        />
                        <Col xl="12">
                            <div className="form-group">
                                <div className="form-control-wrap-flex-wrap justify-end items">
                                    <Button
                                        onClick={handlePreview}
                                        disabled={
                                            loading
                                                ? loading
                                                : !(stepData.second_weight)
                                        }
                                        color="primary"
                                        size="md"
                                    >
                                        {loading ? <Spinner size="sm" color="light" /> : "Preview & Finalize GRN"}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {errorVal && (
                        <div className="mt-4">
                            <Alert color="danger" className="alert-icon">
                                {" "}
                                <Icon name="alert-circle" />
                                {errorVal}{" "}
                            </Alert>
                        </div>
                    )}
                </form>
            </Card>
            {/* Modal For Previewing Before Create */}
            <ModalPreview
                openPreview={openPreview}
                setOpenPreview={setOpenPreview}
                modalTitle={`Finalize GRN`}
                modalDescription={`Please Confirm Goods Received Details and Select 'Finalize GRN' to Book Goods Received Into Storage.`}
                moduleButtonText="Finalize GRN"
                prevType={app_consts.BOOK_PHYSICAL_GOODS_RECEIVED_INTO_STORAGE}
                prevData={preview_step_data}
                buttonFunction={handleSubmit}
            />
        </>
    );
};

export default StorageDetailsStep;