import { createSlice } from "@reduxjs/toolkit";
import * as app_consts from "../../../constants/react_app_consts";

const initialState = {
 add_employee_to_branch_location: { status: "idle" },
};

export const add_employee_to_branch_location_Slice = createSlice({
 name: "add_employee_to_branch_location",
 initialState,
 reducers: {
  success_add_employee_to_branch_location: (state, action) => {
   state.add_employee_to_branch_location = { status: app_consts.SUCCESS, data: action.payload };
  },
  fail_add_employee_to_branch_location: (state, action) => {
   state.add_employee_to_branch_location = { status: app_consts.FAIL, message: action.payload };
  },
  reset_add_employee_to_branch_location: (state) => {
   state.add_employee_to_branch_location = { status: app_consts.IDLE };
  },
  loading_add_employee_to_branch_location: (state) => {
   state.add_employee_to_branch_location = { status: app_consts.LOADING };
  },
 },
});

export const {
 success_add_employee_to_branch_location,
 fail_add_employee_to_branch_location,
 reset_add_employee_to_branch_location,
 loading_add_employee_to_branch_location,
} = add_employee_to_branch_location_Slice.actions;

export default add_employee_to_branch_location_Slice.reducer;
