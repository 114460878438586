import { DataTableItem } from "../../../../../components/Component";
import RadioInput from "../../input/RadioInput";
import ReadOnlyTableRow from "./ReadOnlyTableRow";
import * as app_consts from "../../../../../constants/react_app_consts";
import TextInput from "../../../../components/inputs/TextInput";
export const CustomerFeedBackCheckListData = ({
    data,
    currentItems,
    amendFunction,
    editMode
}) => {

    return (
        <>
            {data.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <DataTableItem key={item?.delivery_note_product_id}>
                                <ReadOnlyTableRow
                                    row_type="first_col"
                                    item={
                                        item?.product_name ? (
                                            <>
                                                {item?.product_name.length > 20
                                                    ? `${`${item?.product_name}`.substring(0, 20)}...`
                                                    : `${item.product_name}`}
                                            </>
                                        ) : (
                                            ""
                                        )
                                    }
                                />
                                <ReadOnlyTableRow
                                    row_type="normal_col"
                                    item={item?.unit_measurement_full_name ? `${item?.unit_measurement_full_name} (${item?.unit_measurement_abbrev})` : ""
                                    }
                                />
                                <ReadOnlyTableRow
                                    row_type="normal_col"
                                    item={item?.packaging_total_number ? item?.packaging_total_number : ""
                                    }
                                />
                                <ReadOnlyTableRow
                                    row_type="normal_col"
                                    item={item?.total_capacity_in_mt ? `${parseFloat(item?.total_capacity_in_mt).toFixed(2)}` : "---"}
                                />

                                {/* <RadioInput
                                                        id={app_consts.LESS_THAN}
                                                        className="custom-control custom-control-md custom-radio"
                                                        handleChange={(e) => amendFunction(item?.delivery_note_product_id, app_consts.LESS_THAN)}
                                                    /> */}
                                {
                                    editMode ?
                                        <>
                                            <ReadOnlyTableRow
                                                row_type="normal_col"
                                                item={[
                                                    <TextInput
                                                        //   value={formData.username}
                                                        colSize="12"
                                                        parentClassName="custom-control custom-control-md custom-radio"
                                                        name="username"
                                                        type="number"
                                                        handleChange={(e) => amendFunction(item?.delivery_note_product_id, e.target.value)}
                                                        //   inputRef={inputRef}
                                                        maxlength="11"
                                                    />
                                                ]}
                                            />
                                        </>
                                        :
                                        <>
                                            <ReadOnlyTableRow
                                                row_type="normal_col"
                                                item={
                                                    item?.customer_packaging_number_feedback ? item?.customer_packaging_number_feedback : "--"}
                                            />
                                        </>
                                }
                            </DataTableItem>
                        </>
                    );
                }
                )
            }
        </>
    );
};