import React, { useEffect, useRef, useState } from 'react';
import LatoRegular from "../../../../utils/fonts/Lato-Regular.ttf";
import LatoBold from '../../../../utils/fonts/Lato-Bold.ttf';
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import Logo from "../../../../images/logo.png";
import moment from 'moment';
import { formatScientificNotation } from '../../../../utils/functionalUtils';

const LoadingOrderPDF = ({ page_data }) => {

  const targetRef = useRef();

  const [pdf_data, setPDFData] = useState(page_data);
  const [groupByBranch, setGroupedByBranch] = useState([]);
  // Register the Lato font
  Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

  useEffect(() => {
    setPDFData(page_data);
    console.log(page_data);
  }, [page_data]);

  const groupByBranchLocation = (data) => {
    return data?.reduce((acc, item) => (
      {
        ...acc,
        [item.branch_location]: [
          ...(acc[item.branch_location] || []),
          item
        ]
      }
    ), {});
  };

  useEffect(() => {
    if (pdf_data?.products && pdf_data?.products?.length > 0)
    {
      const groupedData = groupByBranchLocation(pdf_data?.products);
      setGroupedByBranch(groupedData);
    }
  }, [pdf_data]);

  return (
    <>
      {
        pdf_data && (
          <>
            <Document title={`${pdf_data?.loading_order_ref_no} Loading Order`} ref={targetRef}>
              <Page size="A4" style={{
                flexDirection: "row",
                backgroundColor: "rgba(0,0,0,0)",
                height: "100%",
                width: "100%",
                fontFamily: "Lato"
              }}
                orientation="landscape"
              >
                <View style={{ width: "100%", padding: 5 }}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <View style={{ width: 120 }}>
                        <Image src={Logo} />
                      </View>
                      <View style={{ fontWeight: 600, fontSize: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <View>
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                            <Text style={{ marginLeft: 2, marginBottom: 2, marginTop: 2 }}>Plot 10111 off Mumbwa Road, Chinika Area</Text>
                          </View>
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                            <Text style={{ marginLeft: 2, marginBottom: 2, marginTop: 2 }}>P.O. Box 30203, Lusaka, Zambia.</Text>
                          </View>
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                            <Text style={{ marginLeft: 2 }}>Mobile:</Text>
                            <Text style={{ marginLeft: 2 }}>+260 764 299 293</Text>
                          </View>
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                            <Text style={{ marginLeft: 2 }}>Email:</Text>
                            <Text style={{ marginLeft: 2 }}>office@iagzambia.com</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View>
                      <Text style={{ padding: 10, fontSize: 20, fontWeight: 700, marginRight: 40 }} >LOADING ORDER</Text>
                      <View style={{ display: "flex", flexDirection: "column" }}>

                        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 4 }}>
                          <Text style={{ marginLeft: 5, fontSize: 12, fontWeight: 500 }}>Loading Order Number:</Text>
                          <Text style={{ marginLeft: 5, fontWeight: 600, fontSize: 12, paddingLeft: 2 }}>{pdf_data?.loading_order_ref_no || "---"}</Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2, marginTop: 2 }}>
                          <Text style={{ marginLeft: 5, fontSize: 12, fontWeight: 500 }}>Date Created: </Text>
                          <Text style={{ marginLeft: 5, fontWeight: 600, fontSize: 12, paddingLeft: 2 }}>{pdf_data?.inserted_at ? moment(pdf_data?.inserted_at).format("MMMM Do, YYYY") : "---"}</Text>
                        </View>
                      </View>
                    </View>
                  </View>


                  {Object.keys(groupByBranch).length > 0 && (
                    Object.entries(groupByBranch).map(([branch, items]) => (
                      <View style={{ marginTop: 7, marginX: 20, width: "100%", paddingLeft: 20, paddingRight: 20 }} className='mt-4'>
                        <Text style={{ fontSize: 16, color: "#374151", marginBottom: "5px" }}>
                          Branch Location: <Text style={{ fontWeight: 600, fontSize: 16, color: "#000", marginBottom: "5px", textTransform: "capitalize" }}>{branch}</Text>
                        </Text>
                        <Text style={{ fontSize: 16, color: "#374151", marginBottom: "5px" }}>
                          Customer: <Text style={{ fontWeight: 600, fontSize: 16, color: "#000", marginBottom: "5px", textTransform: "capitalize" }}>{pdf_data?.consigner?.username || "---"}</Text>
                        </Text>
                        <Text style={{ fontSize: 16, color: "#374151", marginBottom: "5px" }}>
                          Contact Person: <Text style={{ fontWeight: 600, fontSize: 16, color: "#000", marginBottom: "5px", textTransform: "capitalize" }}>{pdf_data?.contact_person ? `${pdf_data?.contact_person} (${pdf_data?.contact_mobile})` : "---"}</Text>
                        </Text>
                        <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14, marginTop: 6 }}>
                          <Text style={{ fontWeight: 600, width: "18%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Product Name</Text>
                          <Text style={{ fontWeight: 600, width: "15%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Packet Size</Text>
                          <Text style={{ fontWeight: 600, width: "15%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Package No</Text>
                          <Text style={{ fontWeight: 600, width: "15%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Quantity</Text>
                          <Text style={{ fontWeight: 600, width: "17%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Quantity (MT)</Text>
                          <Text style={{ fontWeight: 600, width: "20%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Delivery Location</Text>
                        </View>

                        {items.map(item => (
                          <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14 }}>
                            <Text style={{ width: "18%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{item?.product_name || "---"}</Text>
                            <Text style={{ width: "15%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{item?.packaging_total_number || "---"}</Text>
                            <Text style={{ width: "15%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{item?.total_capacity_selected_measurement ? `${parseFloat(item?.total_capacity_selected_measurement || 0.00)?.toFixed(0)}` : "---"}</Text>
                            <Text style={{ width: "15%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{item?.product_selected_measurement ? formatScientificNotation(item?.product_selected_measurement) : "---"}</Text>
                            <Text style={{ width: "17%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{item?.total_mt_capacity ? `${parseFloat(item?.total_mt_capacity || 0.00)?.toFixed(2)} MTs ` : "---"}</Text>
                            <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{item?.delivery_name || "---"}</Text>
                          </View>
                        ))}
                      </View>
                    ))
                  )}
                  <View style={{ marginTop: 7, marginX: 20, width: "100%", paddingLeft: 20, paddingRight: 20 }} className='mt-4'>
                    <Text style={{ fontSize: 16, color: "#374151", marginBottom: "5px" }}>
                      Truck Details
                    </Text>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14, marginTop: 6 }}>
                      <Text style={{ fontWeight: 600, width: "20%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Truck Reg</Text>
                      <Text style={{ fontWeight: 600, width: "20%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Trailer Reg 1</Text>
                      <Text style={{ fontWeight: 600, width: "20%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Trailer Reg 2</Text>
                      <Text style={{ fontWeight: 600, width: "20%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Driver Name</Text>
                      <Text style={{ fontWeight: 600, width: "20%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Transporter</Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14 }}>
                      <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{pdf_data?.truck_license_plate || "---"}</Text>
                      <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{pdf_data?.trailer_one_no || "---"}</Text>
                      <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{pdf_data?.trailer_two_no || "---"}</Text>
                      <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{pdf_data?.truck_driver_name || "---"}</Text>
                      <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{pdf_data?.transporter_name || "---"}</Text>
                    </View>
                  </View>

                  <View style={{ marginTop: 7, marginX: 20, width: "100%", paddingLeft: 20, paddingRight: 20 }} className='mt-4'>
                    {
                      pdf_data?.driver_instructions?.length > 0 ? (
                        <>
                          <Text style={{ fontSize: 16, color: "#374151", marginBottom: "5px" }}>
                            Loading Instructions
                          </Text>
                          <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14, marginTop: 6 }}>
                            <Text style={{ fontWeight: 600, width: "20%", border: "1px solid #000", padding: 5, textAlign: "center" }}>No.</Text>
                            <Text style={{ fontWeight: 600, width: "80%", border: "1px solid #000", padding: 5, textAlign: "center" }}>Instructions</Text>
                          </View>
                        </>
                      )
                        :
                        <>
                          <Text style={{ fontSize: 16, color: "#374151", marginBottom: "5px" }}>
                            Loading Instructions
                          </Text>
                          <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14, marginTop: 6 }}>
                            <Text style={{ fontWeight: 600, width: "100%", padding: 5, textAlign: "center" }}>No Instructions Available</Text>
                          </View>
                        </>
                    }
                    {
                      pdf_data?.driver_instructions?.map((instruction, index) => {
                        return (
                          <>
                            <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', width: "100%", textAlign: "center", fontSize: 14 }}>
                              <Text style={{ width: "20%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{index + 1}</Text>
                              <Text style={{ width: "80%", border: "1px solid #526484", padding: 5, textAlign: "center", fontSize: 12, wordWrap: 'break-word' }}>{instruction}</Text>
                            </View>
                          </>
                        );
                      })
                    }
                  </View>

                </View>
              </Page>
            </Document>
          </>
        )
      }
    </>
  );
};

export default LoadingOrderPDF;