import React, { useEffect, useState } from "react";
import { Alert, Card, CardTitle, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../../../../../components/Component";
import RadioInput from "../../../../../input/RadioInput";
import * as app_consts from "../../../../../../../../constants/react_app_consts";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { employee_dashboard_actions_helper } from "../../../../../../../../actions/actions_helper";
import { fail_weigh_bridge_ticket_type_list, loading_weigh_bridge_ticket_type_list, reset_weigh_bridge_ticket_type_list, success_weigh_bridge_ticket_type_list } from "../../../../../../../../reducers/dashboard_reducers/warehouse/weigh_bridge_ticket_type_list_Slice";
import { RETRIEVE_ACTIVE_EMPLOYEE_BRANCH_LOCATION_LIST_API, RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_LIST } from "../../../../../../../../api/api_data";
import { RETRIEVE_BRANCH_LOCATION_LIST_ACCESS_DENIED_MSG, RETRIEVE_BRANCH_LOCATION_LIST_ERR_MSG, RETRIEVE_BRANCH_LOCATION_LIST_SUCCESS_MSG, RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ACCESS_DENIED_MSG, RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_ERR_MSG, RETRIEVE_WEIGH_BRIDGE_TICKET_TYPE_SUCCESS_MSG } from "../../../../../../../../constants/success_error_messages_consts";
import { useSelector } from "react-redux";
import TextInput from "../../../../../../../components/inputs/TextInput";
import { fail_branch_location_list, loading_branch_location_list, reset_branch_location_list, success_branch_location_list } from "../../../../../../../../reducers/dashboard_reducers/branch_locations/branch_location_list_Slice";
import { messageToast, rSelectMapFun } from "../../../../../../../../utils/Utils";
import { retreive_rSelectVariables } from "../../../../../../../../utils/functionalUtils";

const SelectConsignerStep = ({
  refreshList,
  list_data,
  loadingList,
  company_accounts_list,
  customer_accounts_list,
  weighBridgeFormData,
  setWeighBridgeFormData,
  nextStep,
}) => {

  const dispatch = useDispatch();
  const [errorVal, setErrorVal] = useState("");
  const [loading, setLoading] = useState();
  const [consignerType, setConsignerType] = useState("");
  const [user_list_data, setUserListData] = useState([]);
  const [listLoading, setListLoading] = useState();
  const [company_accounts, setCompanyAccounts] = useState([]);
  const [customer_accounts, setCustomerAccounts] = useState([]);
  const [weigh_bridge_ticket_type_list_data, setwighBridgeTicketTypeListData] = useState([]);
  const [formData, setFormData] = useState(weighBridgeFormData);
  const [branches_loading, setBranchesLoading] = useState(true);
  const [branches_data, setBranchesData] = useState([]);

  useEffect(() => {
    fetch_active_branch_location_list();
  }, []);

  useEffect(() => {
    setFormData(weighBridgeFormData);
  }, [weighBridgeFormData]);

  useEffect(() => {
    setFormData(weighBridgeFormData);
  }, [weighBridgeFormData]);

  const consigner_type_selection = (typeSelection) => {
    setWeighBridgeFormData({
      ...weighBridgeFormData,
      ["consigner_type_id"]: typeSelection,
      ["user_account_id"]: "",
      ["user_account_label"]: "",
    });
    setConsignerType(typeSelection);
  };

  useEffect(() => {
    setUserListData(list_data);
  }, [list_data]);

  useEffect(() => {
    setListLoading(loadingList);
  }, [loadingList]);

  useEffect(() => {
    const mappedList = company_accounts_list?.map((comp_acc) => {
      return {
        value: comp_acc.unique_identification_number,
        label: `${comp_acc.unique_identification_number} - ${comp_acc.company_name}`,
      };
    });
    setCompanyAccounts(mappedList);
  }, [company_accounts_list]);

  useEffect(() => {
    const mappedList = customer_accounts_list?.map((cust_acc) => {
      return {
        value: cust_acc.unique_identification_number,
        label: `${cust_acc.unique_identification_number} - ${cust_acc.first_name} ${cust_acc.surname}`,
      };
    });
    setCustomerAccounts(mappedList);
  }, [customer_accounts_list]);

  // Handle Selection Check
  const handleUserAccountSelection = (value, label) => {
    setWeighBridgeFormData({
      ...weighBridgeFormData,
      ["user_account_id"]: value,
      ["user_account_label"]: label,
    });
  };

  // Handle Selection Check
  const handleBranchLocationsSelection = (value, label) => {
    setWeighBridgeFormData({
      ...weighBridgeFormData,
      ["branch_location_id"]: value,
      ["branch_location_name"]: label,
    });
  };

  // Fetch Logged In User's Branch Locations 
  const fetch_active_branch_location_list = () => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_branch_location_list,
        success_branch_location_list,
        fail_branch_location_list,
        RETRIEVE_BRANCH_LOCATION_LIST_ERR_MSG,
        RETRIEVE_BRANCH_LOCATION_LIST_SUCCESS_MSG,
        app_consts.GET,
        RETRIEVE_ACTIVE_EMPLOYEE_BRANCH_LOCATION_LIST_API,
        null,
        RETRIEVE_BRANCH_LOCATION_LIST_ACCESS_DENIED_MSG
      )
    );
  };

  //List to State of Branch Location List
  const branch_location_list = useSelector((state) => state.branch_location_list.branch_location_list);

  useEffect(() => {
    if (branch_location_list.status !== app_consts.IDLE)
    {
      if (branch_location_list.status === app_consts.LOADING)
      {
        setBranchesLoading(true);
      } else
      {
        setBranchesLoading(false);
        if (branch_location_list.status === app_consts.FAIL)
        {
          messageToast(branch_location_list.message, app_consts.FAIL);
        } else if (branch_location_list.status === app_consts.SUCCESS)
        {
          if (branch_location_list.data)
          {
            setUpBranchesData(branch_location_list.data);
          }
        }
        dispatch(reset_branch_location_list());
      }
    }
  }, [branch_location_list]);

  // Set Up Branches Data
  const setUpBranchesData = async (data) => {

    let mappedData = [];

    await Promise.all(
      data?.map(async (data_to_map) => {
        const { label, value } = retreive_rSelectVariables(app_consts.BRANCH_LOCATION_MAP, data_to_map);
        const result = await rSelectMapFun(label, value);
        mappedData.push(result);
      })
    );

    setBranchesData(mappedData);
  };


  return (
    <>
      <Card className="card-inner">
        <CardTitle tag="h5">Select Good Received Consigner</CardTitle>
        {errorVal && (
          <div className="mb-3">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" />
              {errorVal}{" "}
            </Alert>
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row>
            <div className="g-4">
              <div className="d-flex justify-between items-center">
                <TextInput
                  label="Ticket Type"
                  required
                  type="text"
                  id_val="weigh_bridge_ticket_type_id"
                  maxlength="100"
                  value={weighBridgeFormData.ticket_type}
                  disabled
                  colSize="5"
                  name="weigh_bridge_ticket_type_id"
                />
                <Col size={5}>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label text-md" htmlFor="branch_location_id">
                        Branch Location<span className='text-danger pr-2'>{` *`}</span>
                      </label>
                    </div>
                    <div className="form-control-wrap align-items-center d-flex flex-row justify-between">
                      <div className="w-100">
                        <RSelect
                          options={branches_data}
                          className="w-100"
                          placeholder="Search"
                          id="branch_location_id"
                          onChange={(e) => handleBranchLocationsSelection(e.value, e.label)}
                          // onInputChange={(e) => onActionText(e)}
                          isDisabled={listLoading}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
              <Col className="p-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="">
                    Consigner Type<span className='text-danger pr-2'>{` *`}</span>
                  </label>
                  <div className="w-80 form-control-wrap d-flex flex-row">
                    <RadioInput
                      id={app_consts.UAG_CONSIGNER_TYPE}
                      label="UAG"
                      className="custom-control custom-control-md custom-radio"
                      handleChange={(e) => { setWeighBridgeFormData({ ...weighBridgeFormData, ["consigner_type_id"]: e, ["user_account_id"]: "UAG", ["user_account_label"]: "UAG", }); setConsignerType(e); }}
                    />
                    <RadioInput
                      id={app_consts.COMPANY_CONSIGNER_TYPE}
                      label="Company"
                      className="custom-control custom-control-md custom-radio"
                      handleChange={consigner_type_selection}
                    />
                    <RadioInput
                      id={app_consts.CUSTOMER_CONSIGNER_TYPE}
                      label="Individual Customer"
                      className="custom-control custom-control-md custom-radio"
                      handleChange={consigner_type_selection}
                    />
                  </div>
                </div>
              </Col>
            </div>
            {(consignerType === 2 || consignerType === 3) && (
              <>
                <div className="mt-2 mb-2">
                  <hr />
                </div>
                <Col>
                  <div className="form-group">
                    <label className="form-label mb-1 text-md" htmlFor="username">
                      {`Search for ${consignerType === 2 ? ` Company ` : consignerType === 3 ? `Customer ` : ""} by ${consignerType === 2 ? `Company ` : consignerType === 3 ? `Customer ` : ""
                        } Account Number or Name`}<span className='text-danger pr-2'>{` *`}</span>
                    </label>
                    {consignerType === 2 && (
                      <div className="form-control-wrap align-items-center w-65 d-flex flex-row mb-2 justify-between">
                        <div className="w-80">
                          <RSelect
                            options={company_accounts}
                            className="w-100"
                            placeholder="Search"
                            id="username"
                            onChange={(e) => handleUserAccountSelection(e.value, e.label)}
                            // onInputChange={(e) => onActionText(e)}
                            isDisabled={listLoading}
                          />
                        </div>
                        <div className="w-15">
                          {/* // Waiting for List to Load */}
                          {listLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            <Link
                              to="#reload"
                              onClick={(e) => {
                                e.preventDefault();
                                refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                              }}
                            >
                              <Icon name="reload-alt btn-icon fs-22px"></Icon>
                            </Link>
                          )}
                          {/*  } */}
                        </div>
                      </div>
                    )}
                    {consignerType === 3 && (
                      <div className="form-control-wrap align-items-center w-65 d-flex flex-row mb-2 justify-between">
                        <div className="w-80">
                          <RSelect
                            options={customer_accounts}
                            className="w-100"
                            placeholder="Search"
                            id="username"
                            onChange={(e) => handleUserAccountSelection(e.value, e.label)}
                            // onInputChange={(e) => onActionText(e)}
                            isDisabled={listLoading}
                          />
                        </div>
                        <div className="w-15">
                          {/* // Waiting for List to Load */}
                          {listLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            <Link
                              to="#reload"
                              onClick={(e) => {
                                e.preventDefault();
                                refreshList(app_consts.REFRESH_USER_ACCOUNTS);
                              }}
                            >
                              <Icon name="reload-alt btn-icon fs-22px"></Icon>
                            </Link>
                          )}
                          {/*  } */}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </>
            )}
            <Col xl="12" className="mb-3 mt-5">
              <div className="form-group">
                <div className="form-control-wrap-flex-wrap justify-start">
                  <Button
                    onClick={nextStep}
                    color="primary"
                    size="lg"
                    disabled={
                      consignerType === 1
                        ? !(formData.consigner_type_id && formData.weigh_bridge_ticket_type_id && formData.branch_location_id)
                        : !(
                          formData.consigner_type_id &&
                          formData.user_account_id &&
                          formData.user_account_label && formData.weigh_bridge_ticket_type_id && formData.branch_location_id
                        )
                    }
                  >
                    {loading ? <Spinner size="sm" color="light" /> : "Next"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default SelectConsignerStep;
