import React, { useEffect, useState } from 'react';
import Head from '../../../layout/head/Head';
import { useNavigate, useParams } from 'react-router';
import Content from '../../../layout/content/Content';
import PageHeading from '../components/page_heading/PageHeading';
import { Block, BlockHead, BlockTitle } from '../../../components/Component';
import { Card, Spinner } from 'reactstrap';
import CardTabs from '../components/cards/CardTabs';
import { employee_dashboard_actions_helper } from '../../../actions/actions_helper';
import { fail_retrieve_loading_order_details, loading_retrieve_loading_order_details, reset_retrieve_loading_order_details, success_retrieve_loading_order_details } from '../../../reducers/dashboard_reducers/loading_orders/retrieve_loading_order_details_Slice';
import { RETRIEVE_LOADING_ORDER_DETAILS_ACCESS_DENIED_MSG, RETRIEVE_LOADING_ORDER_DETAILS_ERR_MSG, RETRIEVE_LOADING_ORDER_DETAILS_SUCCESS_MSG } from '../../../constants/success_error_messages_consts';
import { useDispatch } from 'react-redux';
import { RETRIEVE_LOADING_ORDER_DETAILS_API } from '../../../api/api_data';
import * as app_consts from "../../../constants/react_app_consts";
import { messageToast } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import ProfileDetailText from '../components/text/ProfileDetailText';
import moment from 'moment';
import ReadOnlyTable from '../components/table/ReadOnlyTable';
import { BiErrorCircle } from 'react-icons/bi';
import LoadingOrderPDF from '../components/pdf/LoadingOrderPDF';
import { PDFViewer } from '@react-pdf/renderer';
import { findModuleAction } from '../../../utils/functionalUtils';

const initial_actions_data = {
  view_loading_orders: false,
  create_loading_order: false,
};
const type = app_consts.CREATE_PHYSICAL_LOADING_ORDER;

const LoadingOrderDetails = () => {

  const { loading_order_ref_no } = useParams();
  const [loading, setLoading] = useState(true);
  const [activeCardTab, setActiveCardTab] = useState("Main Details");
  const [dataFetched, setDataFetched] = useState(false);
  const [page_data, setPageData] = useState([]);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [account_actions, setAccountActions] = useState([]);
  const [componentData, setComponentData] = useState(initial_actions_data);
  const navigate = useNavigate();
  const [loading_order_history, setLoadingOrderHistory] = useState([]);


  // Card Tabs
  const tabs = [
    {
      tabName: "Main Details"
    },
    {
      tabName: "Product Information"
    },
    {
      tabName: "Loading Order History"
    },
    {
      tabName: "Loading Order PDF"
    },
  ];

  //  RETRIEVE_LOADING_ORDERS_API
  useEffect(() => {
    dispatch(
      employee_dashboard_actions_helper(
        loading_retrieve_loading_order_details,
        success_retrieve_loading_order_details,
        fail_retrieve_loading_order_details,
        RETRIEVE_LOADING_ORDER_DETAILS_ERR_MSG,
        RETRIEVE_LOADING_ORDER_DETAILS_SUCCESS_MSG,
        app_consts.GET,
        `${RETRIEVE_LOADING_ORDER_DETAILS_API}/${loading_order_ref_no}`,
        { loading_order_ref_no: loading_order_ref_no },
        RETRIEVE_LOADING_ORDER_DETAILS_ACCESS_DENIED_MSG
      )
    );
    if (localStorage.getItem("log_cred"))
    {
      setAccountActions(JSON.parse(localStorage.getItem('log_cred'))?.user_actions);
    }
  }, []);

  // Listen to Changes In Account Actions
  useEffect(() => {
    if (account_actions)
    {
      let actions_data = initial_actions_data;
      account_actions?.map((module) => {
        if (module.module_name === app_consts.WAREHOUSE_MANAGEMENT) 
        {
          // Actions Data
          if (findModuleAction(app_consts.CREATE_LOADING_ORDER, module))
          {
            actions_data.create_loading_order = true;
          }
        }
      });
      setComponentData(actions_data);
    }
  }, [account_actions]);

  // Listen to Changes
  const loading_order_details_listener = useSelector((state) => state.retrieve_loading_order_details.retrieve_loading_order_details);
  // 
  useEffect(() => {
    if (loading_order_details_listener.status !== app_consts.IDLE)
    {
      if (loading_order_details_listener.status === app_consts.LOADING)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (loading_order_details_listener.status === app_consts.FAIL)
        {
          setError(loading_order_details_listener.message);
          messageToast(loading_order_details_listener.message, app_consts.FAIL);
        } else if (loading_order_details_listener.status === app_consts.SUCCESS)
        {
          setPageData(loading_order_details_listener.data);
          setLoadingOrderHistory(loading_order_details_listener.data?.inventory_history);
          setDataFetched(true);
        }
        dispatch(reset_retrieve_loading_order_details());
      }
    }
  }, [loading_order_details_listener]);


  const pageHeadingActions = (type, received_data) => {
    if (type === app_consts.COMP_AMEND_DELIVERY_NOTE_STATUS_ACTIONS)
    {
      // amend_delivery_note_status_func(received_data);
    }
    else if (type === app_consts.CONTINUE_PROCESSING_LOADING_ORDER_ACTIONS)
    {
      navigate(`/dashboard/loading_orders/continue_processing_physical/${loading_order_ref_no}`);
    }
  };


  return (
    <>
      <Head title="Loading Order Details" />
      <Content>
        {/* Table Page Heading */}
        <PageHeading
          title="Loading Order Details"
          title_addition={loading_order_ref_no}
          description="Details of Loading Order "
          backToText="Loading Orders"
          backToLink={`/dashboard/loading_orders`}
          showDropDown={page_data?.loading_order_status === app_consts.UNDERGOING_PROCESSING}
          headingRightData={componentData}
          type={type}
          pageHeadingActions={pageHeadingActions}
          data={page_data}
        />

        <Block>
          <Card>
            <div className="card-aside-wrap" id="user-detail-block">
              <div className="card-content">
                <CardTabs cardTabs={tabs} activeCardTab={activeCardTab} setActiveCardTab={setActiveCardTab} dataFetched={setDataFetched} />
                {
                  loading ?
                    <>
                      <div className='p-5 d-flex align-center justify-center'>
                        <Spinner />
                      </div>
                    </>
                    :
                    <>
                      <div className="card-inner">
                        {
                          activeCardTab === "Main Details" && (
                            <>
                              <Block>
                                <BlockHead>
                                  <BlockTitle tag="h5">Loading Order Summary</BlockTitle>
                                  <p>A Brief Summary of Loading Order Details.</p>
                                </BlockHead>
                                <div className="profile-ud-list">
                                  <ProfileDetailText label="Goods Received Reference Number" text={page_data?.loading_order_ref_no || "---"} />
                                  <ProfileDetailText label="Status" text={page_data?.loading_order_status || "---"} textClassName={page_data?.loading_order_status_id === app_consts.WEIGH_BRIDGE_TICKET_PROCESSING_COMPLETE ? "text-success" : "text-warning"} />
                                  <ProfileDetailText label="Date Created" text={page_data?.inserted_at ? moment(page_data?.goods_received?.date_created).format("MMMM Do, YYYY") : "---"} />
                                  <ProfileDetailText label="Total Quantity" text={page_data?.total_quantity_in_mt ? page_data?.total_quantity_in_mt : "---"} />
                                </div>
                                <BlockHead>
                                  <BlockTitle tag="h5">Consigner Details</BlockTitle>
                                  <p>Entity that the Loading Order Belongs to</p>
                                </BlockHead>
                                <div className="profile-ud-list">
                                  <ProfileDetailText label="User ID" text={page_data?.consigner?.unique_identification_number || "---"} />
                                  <ProfileDetailText label="Name" text={page_data?.consigner?.username || "---"} />
                                  <ProfileDetailText label="Email Address" text={page_data?.email_address || "---"} />
                                  <ProfileDetailText label="Mobile number" text={page_data?.consigner?.mobile || "---"} />
                                  <ProfileDetailText label="Contact person" text={page_data?.contact_person || "---"} />
                                  <ProfileDetailText label="Contact Mobile" text={page_data?.contact_mobile || "---"} />
                                  <ProfileDetailText label="Mobile number" text={page_data?.consigner?.mobile || "---"} />
                                </div>
                                <BlockHead>
                                  <BlockTitle tag="h5">Truck Details</BlockTitle>
                                  <p>Transport Details</p>
                                </BlockHead>
                                <div className="profile-ud-list">
                                  <ProfileDetailText label="Transporter" text={page_data?.transporter_name || "---"} />
                                  <ProfileDetailText label="Truck Driver Name" text={page_data?.truck_driver_name || "---"} />
                                  <ProfileDetailText label="Truck Reg No" text={page_data?.truck_license_plate || "---"} />
                                  <ProfileDetailText label="Trailer One" text={page_data?.trailer_one_no || "---"} />
                                  <ProfileDetailText label="Trailer Two" text={page_data?.trailer_two_no || "---"} />
                                </div>
                                <BlockHead>
                                  <BlockTitle tag="h5">Loading Instructions</BlockTitle>
                                </BlockHead>
                                {/* Table */}
                                <div className="my-3">
                                  <ReadOnlyTable data={page_data?.driver_instructions} tableType={app_consts.DRIVER_INSTRUCTIONS_TABLE} />
                                </div>
                              </Block>
                            </>
                          )
                        }
                        {
                          activeCardTab === "Product Information" && (
                            <>
                              <Block>
                                <BlockHead>
                                  <BlockTitle tag="h5">Loading Order Products</BlockTitle>
                                  <p>Products {page_data?.products?.length}</p>
                                </BlockHead>
                                {/* Table */}
                                <div className="my-3">
                                  <ReadOnlyTable data={page_data?.products} tableType={app_consts.ADD_LOADING_ORDER_PRODUCT_TABLE} />
                                </div>
                              </Block>
                            </>
                          )
                        }
                        {
                          activeCardTab === "Loading Order History" && (
                            <>
                              <Block>
                                <BlockHead>
                                  <BlockTitle tag="h5">Loading Order History</BlockTitle>
                                  <p>Historical Data on Loading Order Amendments</p>
                                </BlockHead>
                                {/* Table */}
                                <div className="my-3">
                                  <ReadOnlyTable data={loading_order_history} tableType={app_consts.GRN_HISTORY} />
                                </div>
                              </Block>
                            </>
                          )
                        }

                        {
                          activeCardTab === "Loading Order PDF" && (
                            <>
                              {
                                page_data?.loading_order_status?.toLowerCase() !== app_consts.UNDERGOING_PROCESSING?.toLowerCase() ?
                                  <>
                                    <Block>
                                      <BlockHead>
                                        <BlockTitle tag="h5">Loading Order PDF</BlockTitle>
                                        <p>Printable Doucment with Loading Order Details</p>
                                      </BlockHead>
                                      <div className='p-2 border text-gray'>
                                        <div style={{ height: "90vh", width: "100%" }} className='border-2 border-text-gray-400'>
                                          <PDFViewer height="100%" width="100%" showToolbar>
                                            <LoadingOrderPDF page_data={page_data} />
                                          </PDFViewer>
                                        </div>
                                      </div>
                                    </Block>
                                  </>
                                  :
                                  <>
                                    <div className="mt-3 p-5 text-soft alert-icon text-center fw-bold fs-15px">
                                      <BiErrorCircle className="text-danger fs-13" />
                                      <p>Cannot View Loading Order PDF.</p>
                                      <p>Loading Order Processing is Incomplete.</p>
                                    </div>
                                  </>
                              }
                            </>
                          )
                        }
                      </div>
                    </>
                }
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </>
  );
};

export default LoadingOrderDetails;

//   driver_instructions text[] COLLATE pg_catalog."default",
//   created_by_id integer NOT NULL,
//   inventory_history jsonb[] NOT NULL,
//   loading_order_product_info jsonb,