import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'reactstrap';
import { Col, Row } from '../../../../../../components/Component';
import StepGuideCard from '../../wizard_steps_guide/StepGuide';
import { useParams } from 'react-router';
import ConsignerAndTruckStep from './steps/create_loading_order_steps/ConsignerAndTruckStep';
import { employee_dashboard_actions_helper } from '../../../../../../actions/actions_helper';
import { fail_user_accounts_list, loading_user_accounts_list, reset_user_accounts_list, success_user_accounts_list } from '../../../../../../reducers/dashboard_reducers/user_accounts/user_accounts_list_Slice';
import { CONTINUE_PROCSSING_LOADING_ORDER_ACCESS_DENIED_MSG, CONTINUE_PROCSSING_LOADING_ORDER_ERR_MSG, CONTINUE_PROCSSING_LOADING_ORDER_SUCCESS_MSG, RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG, RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ERR_MSG, RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_SUCCESS_MSG } from '../../../../../../constants/success_error_messages_consts';
import { CONTINUE_PROCESSING_LOADING_ORDER_API, RETRIEVE_ACTIVE_USER_ACCOUNT_LIST } from '../../../../../../api/api_data';
import { messageToast } from '../../../../../../utils/Utils';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as app_consts from "../../../../../../constants/react_app_consts";
import AddLoadingOrderProducts from './steps/create_loading_order_steps/AddLoadingOrderProducts';
import LoadingOrderPDFStep from './steps/create_loading_order_steps/LoadingOrderPDFStep';
import { fail_continue_processing_loading_order, loading_continue_processing_loading_order, reset_continue_processing_loading_order, success_continue_processing_loading_order } from '../../../../../../reducers/dashboard_reducers/loading_orders/continue_processing_loading_order_Slice';

const initialState = { truck_license_plate: "", truck_driver_name: "", transporter_name: "", contact_person: "", driver_instructions: [], consigner_type_id: "", trailer_one_no: "", trailer_two_no: "", user_account_id: "", contact_name: "", contact_mobile: "", add_driver_instructions: "", user_account_id: "", loading_order_type: app_consts.SINGLE_L0ADING_ORDER_TYPE };


const CreateLoadingOrderIndex = ({ wizardType }) => {

    const { loading_order_ref_no } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [step, setStep] = useState(1);
    const [user_list_data, setUserListData] = useState([]);
    const [userListLoading, setUserListLoading] = useState(false);
    const [company_accounts, setCompanyAccounts] = useState([]);
    const [customer_accounts, setCustomerAccounts] = useState([]);

    const [wizard_data, setWizardData] = useState(initialState);

    useEffect(() => {
        fetchActiveTruckCustCompAccs();
        if (!loading_order_ref_no)
        {
            setLoading(false);
        }
        else
        {
            setLoading(true);
            dispatch(
                employee_dashboard_actions_helper(
                    loading_continue_processing_loading_order,
                    success_continue_processing_loading_order,
                    fail_continue_processing_loading_order,
                    CONTINUE_PROCSSING_LOADING_ORDER_ERR_MSG,
                    CONTINUE_PROCSSING_LOADING_ORDER_SUCCESS_MSG,
                    app_consts.GET,
                    `${CONTINUE_PROCESSING_LOADING_ORDER_API}/${loading_order_ref_no}`,
                    null,
                    CONTINUE_PROCSSING_LOADING_ORDER_ACCESS_DENIED_MSG
                )
            );

        }
    }, []);

    // Listen to Continue Processing Loading Order
    const continue_processing_loading_order_listener = useSelector((state) => state.continue_processing_loading_order.continue_processing_loading_order);
    // 
    useEffect(() => {
        if (continue_processing_loading_order_listener.status !== app_consts.IDLE)
        {
            if (continue_processing_loading_order_listener.status === app_consts.LOADING)
            {
                setLoading(true);
            } else
            {
                setLoading(false);
                if (continue_processing_loading_order_listener.status === app_consts.FAIL)
                {
                    setError(continue_processing_loading_order_listener.message);
                } else if (continue_processing_loading_order_listener.status === app_consts.SUCCESS)
                {
                    setWizardData(continue_processing_loading_order_listener?.data?.params);
                    setStep(continue_processing_loading_order_listener?.data?.step);
                }
                dispatch(reset_continue_processing_loading_order());
            }
        }
    }, [continue_processing_loading_order_listener]);


    const refreshList = (type) => {
        if (type === app_consts.REFRESH_USER_ACCOUNTS)
        {
            fetchActiveTruckCustCompAccs();
        }
    };

    const fetchActiveTruckCustCompAccs = () => {
        dispatch(
            employee_dashboard_actions_helper(
                loading_user_accounts_list,
                success_user_accounts_list,
                fail_user_accounts_list,
                RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ERR_MSG,
                RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_SUCCESS_MSG,
                app_consts.GET,
                RETRIEVE_ACTIVE_USER_ACCOUNT_LIST,
                null,
                RETRIEVE_ACTIVE_USER_ACCOUNT_LIST_ACCESS_DENIED_MSG
            )
        );
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };
    // Listen to State of user_account_list
    const user_accounts_list = useSelector((state) => state.user_accounts_list.user_accounts_list);

    useEffect(() => {
        if (user_accounts_list.status !== app_consts.IDLE)
        {
            if (user_accounts_list.status === app_consts.LOADING)
            {
                setUserListLoading(true);
            } else
            {
                setUserListLoading(false);
                if (user_accounts_list.status === app_consts.FAIL)
                {
                    messageToast(user_accounts_list.message, app_consts.FAIL);
                } else if (user_accounts_list.status === app_consts.SUCCESS)
                {
                    setUserListData(user_accounts_list.data);
                }
                dispatch(reset_user_accounts_list());
            }
        }
    }, [user_accounts_list]);

    // Add the Data to A Map for RSelect
    useEffect(() => {
        if (user_list_data.customer_accounts)
        {
            setCustomerAccounts(user_list_data.customer_accounts);
        }
        if (user_list_data.company_accounts)
        {
            setCompanyAccounts(user_list_data.company_accounts);
        }
    }, [user_list_data]);

    return (
        <>
            {
                loading ?
                    <>
                        <div className='w-full p-5 d-flex justify-center items-center'>
                            <Spinner />
                        </div>
                    </>
                    :
                    error && error.length > 0 ?
                        <>
                            <div className='text-soft alert-icon w-full p-5 d-flex justify-center items-center fw-bold fs-18px'>
                                {error}
                            </div>
                        </>
                        :
                        <>
                            <Card className="card-bordered">
                                <Row className="g-0 col-sep col-sep-md col-sep-xl">
                                    <StepGuideCard sentStep={step} wizard_type={wizardType} wizard_title="Loading Order Progress" />
                                    <Col className="col-md-8 col-xl-8">
                                        {
                                            step === 1 ? (
                                                <ConsignerAndTruckStep
                                                    refreshList={refreshList}
                                                    list_data={user_list_data}
                                                    loadingList={userListLoading}
                                                    company_accounts_list={company_accounts}
                                                    customer_accounts_list={customer_accounts}
                                                    nextStep={nextStep}
                                                    wizard_data={wizard_data}
                                                    setWizardData={setWizardData}
                                                />
                                            ) :
                                                step === 2 ? (
                                                    <AddLoadingOrderProducts
                                                        refreshList={refreshList}
                                                        list_data={user_list_data}
                                                        loadingList={userListLoading}
                                                        company_accounts_list={company_accounts}
                                                        customer_accounts_list={customer_accounts}
                                                        nextStep={nextStep}
                                                        wizard_data={wizard_data}
                                                        setWizardData={setWizardData}
                                                    />
                                                ) :
                                                    step === 3 ? (
                                                        <LoadingOrderPDFStep
                                                            nextStep={nextStep}
                                                            wizard_data={wizard_data}
                                                            setWizardData={setWizardData}
                                                        />
                                                    ) :
                                                        ""
                                        }
                                    </Col>
                                </Row>
                            </Card>
                        </>
            }
        </>
    );
};

export default CreateLoadingOrderIndex;