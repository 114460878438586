import React, { useEffect, useState } from 'react';
// import Logo from "../../../../layout/logo/Logo";
import { Button, Icon } from '../../../../components/Component';
import moment from 'moment';
import Logo from "../../../../images/logo.png";
import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import LatoRegular from "../../../../utils/fonts/Lato-Regular.ttf";
import LatoBold from '../../../../utils/fonts/Lato-Bold.ttf';

const DeliveryNotePDF = ({ page_data }) => {

    const [pdf_data, setPDFData] = useState(page_data);
    const [total_mt_cap, setTotalMTCap] = useState(0);
    // Register the Lato font
    Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    useEffect(() => {
        setPDFData(page_data);
        const totalMtCapacity = pdf_data?.product_list?.reduce((total, product) => {
            return total + parseFloat(product?.total_capacity_in_mt || 0);
        }, 0).toFixed(2);
        setTotalMTCap(totalMtCapacity);
        console.log(page_data);
    }, [page_data]);

    return (
        <>  {
            pdf_data &&
            (
                <>
                    <Document /* title={`${pdf_data?.goods_received?.goods_received_ref_number} Goods Received Number`} */>
                        {/* <div className="invoice-action">
                         <Link to={`${process.env.PUBLIC_URL}/invoice-print/${user.id}`} target="_blank"> 
                        <Button size="lg" color="primary" outline className="btn-icon btn-white btn-dim">
                            <Icon name="printer-fill"></Icon>
                        </Button>
                         </Link> 
                    </div> */}
                        <Page size="A4" style={{
                            flexDirection: "row",
                            backgroundColor: "rgba(0,0,0,0)",
                            height: "100%",
                            width: "100%",
                            fontFamily: "Lato"
                        }}
                            orientation="landscape"
                        >
                            <View style={{ width: "100%", padding: 5 }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%"
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "50%"
                                    }}>
                                        <View style={{ width: 130 }}>
                                            <Image src={Logo} />
                                        </View>
                                        <View style={{ fontWeight: 600, fontSize: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}>
                                                    <Text style={{ marginLeft: 2 }}>Plot 10111 off Mumbwa Road, Chinika Area</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}>
                                                    <Text style={{ marginLeft: 2 }}>P.O. Box 30203, Lusaka, Zambia.</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}>
                                                    <Text style={{ marginLeft: 2 }}>Mobile: +260 764 299 293</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                    <Text style={{ marginLeft: 2 }}>Email: office@iagzambia.com</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={{ width: "50%", display: "flex", alignItems: "flex-end" }}>
                                        <View
                                            style={{
                                                paddingRight: 10,
                                                width: "100%", // Ensures it takes full width
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-end", // Push content to the right
                                                alignItems: "center",
                                                marginBottom: 5,
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    backgroundColor: "#2c3782",
                                                    padding: 10,
                                                    color: "white",
                                                    border: "1px solid",
                                                    borderRadius: 12,
                                                    fontSize: 18,
                                                    fontWeight: "700",
                                                    textAlign: "right", // Ensures text inside aligns right
                                                }}
                                            >
                                                DELIVERY NOTE
                                            </Text>
                                        </View>

                                        <View style={{ display: "flex", flexDirection: "column" }}>
                                            <View style={{ display: "flex", flexDirection: "row", marginBottom: 1, fontSize: 12 }}>
                                                <View style={{ width: "35%" }}>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1 }}>D.N:</Text>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1 }}>Branch Location:</Text>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1 }}>Date Dispatched:</Text>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1 }}>Weigh Bridge Ticket No:</Text>
                                                </View>
                                                <View style={{ width: "65%" }}>
                                                    <Text style={{ marginBottom: 1 }}>{pdf_data?.delivery_note?.delivery_note_ref_number}</Text>
                                                    <Text style={{ marginBottom: 1 }}>
                                                        {pdf_data?.branch_location?.branch_area
                                                            ? `${pdf_data?.branch_location?.branch_area}, ${pdf_data?.branch_location?.branch_physical_address}`
                                                            : "---"}
                                                    </Text>
                                                    <Text style={{ marginBottom: 1 }}>
                                                        {pdf_data?.delivery_note?.transit_date_time
                                                            ? moment(pdf_data?.delivery_note?.transit_date_time).format("MMMM Do, YYYY")
                                                            : "---"}
                                                    </Text>
                                                    <Text style={{ marginBottom: 1 }}>
                                                        {pdf_data?.weigh_bridge_ticket?.weigh_bridge_ticket_reference_no || "---"}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>


                                <View style={{ padding: 5, width: "100%" }}>
                                    <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", border: "1px solid #C0C0C0", padding: 5 }} >

                                        <View style={{ display: "flex", flexDirection: "column", width: "50%", fontSize: 12, borderRight: "1px solid #A9A9A9" }}>
                                            <Text style={{ textAlign: "center", textTransform: "uppercase", padding: 2, fontWeight: 600, borderBottom: "1px solid #A9A9A9" }}>Customer Details</Text>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <View style={{ borderBottom: "1px solid #A9A9A9" }}>
                                                </View>
                                                <View style={{ width: "30%", borderRight: "1px solid #A9A9A9" }}>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1 }}>Customer Name:</Text>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1 }}>Delivery Location:</Text>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1 }}>Contact Details:</Text>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1 }}>Comments:</Text>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, color: "transparent" }}>-</Text>
                                                </View>
                                                <View style={{ width: "70%" }}>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>{pdf_data?.consigner?.name || "--"}</Text>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>{pdf_data?.delivery_note?.delivery_location || "--"}</Text>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>{pdf_data?.consigner?.mobile_number || "--"}</Text>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, padding: 1, paddingLeft: 5 }}>{pdf_data?.weigh_bridge_ticket?.comments || "--"}</Text>
                                                </View>
                                            </View>

                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", width: "50%", fontSize: 12 }}>
                                            <Text style={{ textAlign: "center", textTransform: "uppercase", padding: 2, fontWeight: 600, borderBottom: "1px solid #A9A9A9" }}>Transporter Details</Text>
                                            <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 1, marginTop: 1 }}>
                                                <View style={{ borderBottom: "1px solid #A9A9A9" }}>
                                                </View>
                                                <View style={{ width: "35%", borderRight: "1px solid #A9A9A9" }}>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>Truck Reg No.:</Text>

                                                    <View style={{ borderRight: "1px solid #A9A9A9", width: "100%" }}>
                                                        <View style={{ paddingLeft: 5, width: "60%" }}>
                                                            <Text style={{ fontWeight: 600, borderRight: "1px solid #A9A9A9", }}>Trailer No. 1:</Text>
                                                        </View>
                                                        <View>
                                                            <Text style={{ fontWeight: "bold", borderBottom: "1px solid #A9A9A9", marginBottom: 1, marginTop: 1, padding: 1, paddingLeft: 5 }}>{pdf_data?.weigh_bridge_ticket?.trailer_one}</Text>
                                                        </View>
                                                    </View>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>Transporter Name:</Text>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>Driver Name/ Signature:</Text>
                                                    <Text style={{ fontWeight: 600, marginBottom: 1, marginTop: 1, padding: 1, paddingLeft: 5 }}>Driver Contact:</Text>
                                                </View>
                                                <View style={{ width: "65%" }}>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>{pdf_data?.weigh_bridge_ticket?.truck_licence_plate}</Text>

                                                    <View style={{ borderRight: "1px solid #A9A9A9", width: "100%" }}>
                                                        <View style={{ paddingLeft: 5, width: "35%" }}>
                                                            <Text style={{ fontWeight: 600, borderRight: "1px solid #A9A9A9", }}>Trailer No. 2:</Text>
                                                        </View>
                                                        <View>
                                                            <Text style={{ fontWeight: "bold", borderBottom: "1px solid #A9A9A9", marginBottom: 1, marginTop: 1, padding: 1, paddingLeft: 5 }}>{pdf_data?.weigh_bridge_ticket?.trailer_one}</Text>
                                                        </View>
                                                    </View>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>{pdf_data?.weigh_bridge_ticket?.transporter_name}</Text>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, borderBottom: "1px solid #A9A9A9", padding: 1, paddingLeft: 5 }}>{pdf_data?.weigh_bridge_ticket?.truck_driver_name}</Text>
                                                    <Text style={{ marginBottom: 1, marginTop: 1, padding: 1, paddingLeft: 5 }}>{pdf_data?.delivery_note?.customer_receiving_primary_mobile}</Text>
                                                </View>
                                            </View>

                                        </View>
                                    </View>
                                </View>
                                <View style={{ padding: 5, fontSize: 12 }}>
                                    <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", border: "1px solid #C0C0C0", borderRadius: "1px", marginTop: 10 }}>
                                        <View style={{ borderBottom: "1px solid #A9A9A9", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2 }}>
                                                <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Product </Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2 }}>
                                                <Text style={{ textAlign: "center" }}>UOM</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2 }}>
                                                <Text style={{ textAlign: "center" }}>Packet Size</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", borderRight: "2px solid #A9A9A9", padding: 2 }}>
                                                <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Total Kgs</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", padding: 2, borderRight: "2px solid #A9A9A9" }}>
                                                <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Total Lts</Text>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "16.6%", padding: 2 }}>
                                                <Text style={{ textAlign: "center", textTransform: "uppercase" }}>Total MT</Text>
                                            </View>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", borderRadius: "1px" }}>
                                            {(() => {
                                                const productList = pdf_data?.product_list || [];
                                                const minItems = 6; // Set the minimum number of rows

                                                // Fill up the list with placeholders if it's below minItems
                                                const paddedProductList = productList.length < minItems
                                                    ? [...productList, ...Array(minItems - productList.length).fill({ isPlaceholder: true })]
                                                    : productList;

                                                return paddedProductList.map((product, index) => {
                                                    const isPlaceholder = product.isPlaceholder;

                                                    return (
                                                        <View key={index} style={{ display: "flex", flexDirection: "row" }}>
                                                            {/* Product Name */}
                                                            <View style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "space-between",
                                                                width: "16.6%",
                                                                borderRight: isPlaceholder ? "none" : "2px solid #A9A9A9",
                                                                padding: 2,
                                                                borderBottom: isPlaceholder || index === paddedProductList.length - 1 ? "none" : "1px solid #A9A9A9"
                                                            }}>
                                                                <Text style={{ paddingLeft: 5, color: isPlaceholder ? "transparent" : "inherit" }}>
                                                                    {isPlaceholder ? "---" : product?.product_name}
                                                                </Text>
                                                            </View>

                                                            {/* Unit Measurement */}
                                                            <View style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                width: "16.6%",
                                                                borderRight: isPlaceholder ? "none" : "2px solid #A9A9A9",
                                                                padding: 2,
                                                                borderBottom: isPlaceholder || index === paddedProductList.length - 1 ? "none" : "1px solid #A9A9A9",
                                                                textAlign: "center"
                                                            }}>
                                                                <Text style={{ paddingLeft: 5, color: isPlaceholder ? "transparent" : "inherit" }}>
                                                                    {isPlaceholder ? "---" : product?.unit_measurement_abbrev}
                                                                </Text>
                                                            </View>

                                                            {/* Packaging Total Number */}
                                                            <View style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                width: "16.6%",
                                                                borderRight: isPlaceholder ? "none" : "2px solid #A9A9A9",
                                                                padding: 2,
                                                                borderBottom: isPlaceholder || index === paddedProductList.length - 1 ? "none" : "1px solid #A9A9A9",
                                                                textAlign: "center"
                                                            }}>
                                                                <Text style={{ textAlign: "center", color: isPlaceholder ? "transparent" : "inherit" }}>
                                                                    {isPlaceholder ? "---" : product?.packaging_total_number}
                                                                </Text>
                                                            </View>

                                                            {/* Weight Measurement (kg) */}
                                                            <View style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                width: "16.6%",
                                                                borderRight: isPlaceholder ? "none" : "2px solid #A9A9A9",
                                                                padding: 2,
                                                                borderBottom: isPlaceholder || index === paddedProductList.length - 1 ? "none" : "1px solid #A9A9A9",
                                                                textAlign: "center"
                                                            }}>
                                                                <Text style={{ textAlign: "center", color: isPlaceholder ? "transparent" : "inherit" }}>
                                                                    {isPlaceholder ? "---" : (product?.unit_measurement_abbrev?.includes("kg") || product?.unit_measurement_abbrev?.includes("KG")) ? product?.total_capacity_selected_measurement : "--"}
                                                                </Text>
                                                            </View>

                                                            {/* Volume Measurement (lt) */}
                                                            <View style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                width: "16.6%",
                                                                borderRight: isPlaceholder ? "none" : "2px solid #A9A9A9",
                                                                padding: 2,
                                                                borderBottom: isPlaceholder || index === paddedProductList.length - 1 ? "none" : "1px solid #A9A9A9",
                                                                textAlign: "center"
                                                            }}>
                                                                <Text style={{ textAlign: "center", color: isPlaceholder ? "transparent" : "inherit" }}>
                                                                    {isPlaceholder ? "---" : (product?.unit_measurement_abbrev?.includes("lt") || product?.unit_measurement_abbrev?.includes("LT")) ? product?.total_capacity_selected_measurement : "--"}
                                                                </Text>
                                                            </View>

                                                            {/* Total Capacity in MT */}
                                                            <View style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "space-between",
                                                                width: "16.6%",
                                                                textAlign: "center",
                                                                padding: 2,
                                                                borderBottom: isPlaceholder || index === paddedProductList.length - 1 ? "none" : "1px solid #A9A9A9"
                                                            }}>
                                                                <Text style={{ textAlign: "center", color: isPlaceholder ? "transparent" : "inherit" }}>
                                                                    {isPlaceholder ? "---" : product?.total_capacity_in_mt}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    );
                                                });
                                            })()}
                                        </View>
                                    </View>
                                </View>
                                <View style={{ padding: 5, fontSize: 10 }}>
                                    <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "30%", padding: 2 }}>
                                            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                <View>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase" }}>D/Note Issued BY:</Text>
                                                </View>
                                                <View>
                                                    <Text style={{ textAlign: "left", marginLeft: 2, textTransform: "uppercase", fontWeight: "bold" }}>{pdf_data?.delivery_note?.issued_by_name}</Text>
                                                </View>
                                            </View>

                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "25%", padding: 2 }}>
                                            <Text style={{ textAlign: "center", fontWeight: "bold" }}></Text>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "15%", padding: 2 }}>
                                            <Text style={{ textAlign: "center", fontWeight: "bold" }}></Text>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "15%", padding: 2 }}>
                                            <Text style={{ textAlign: "right", textTransform: "uppercase" }}>Total Quantity</Text>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "15%", padding: 2, borderBottom: "1px solid #A9A9A9" }}>
                                            <Text style={{ textAlign: "center", fontWeight: "bold" }}>{total_mt_cap}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ padding: 5 }}>
                                    <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", border: "1px solid #C0C0C0", borderRadius: "1px", marginTop: 10 }}>
                                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "50%", borderRight: "2px solid #A9A9A9" }}>
                                                <View style={{ borderBottom: "1px solid #A9A9A9" }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase", fontSize: 12, fontWeight: "bold", padding: 1 }}>Customer Receiving Details</Text>
                                                </View>
                                                <View style={{ fontSize: 12 }}>
                                                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9", fontSize: 12 }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontWeight: "bold" }}>Receiver Name</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text>{pdf_data?.delivery_note?.customer_receiving_name}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontWeight: "bold" }}>Receiver Signature</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text></Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid #A9A9A9" }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontWeight: "bold" }}>Receiver Contact Details</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text>{pdf_data?.delivery_note?.customer_receiving_primary_mobile}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ display: "flex", flexDirection: "row" }}>
                                                        <View style={{ paddingLeft: 5, width: "40%", borderRight: "1px solid #A9A9A9" }}>
                                                            <Text style={{ fontWeight: "bold" }}>Date Received:</Text>
                                                        </View>
                                                        <View style={{ paddingLeft: 5 }}>
                                                            <Text>{pdf_data?.delivery_note?.delivery_date_time ? moment(page_data?.delivery_note?.delivery_date_time).format("Do MMMM , YYYY h:mm:ss") : "---"}</Text>

                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", width: "18%", borderRight: "2px solid #A9A9A9" }}>
                                                <View style={{ borderBottom: "1px solid #A9A9A9" }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase", fontSize: 12, fontWeight: "bold", padding: 1 }}>Customer Stamp</Text>
                                                </View>
                                                {
                                                    pdf_data?.delivery_note?.delivery_date_time && (
                                                        <>
                                                            <View style={{ width: "95%", height: 70, margin: "auto", border: "2px solid black", alignSelf: "center", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                                                                {/* Logo and Text Container */}
                                                                <View style={{ position: "relative", display: "felx", flexDirection: "row", top: 1, left: 1, display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 2 }}>
                                                                    <Image source={Logo} style={{ width: 20, height: 20, marginRight: 2 }} />
                                                                    <Text style={{ fontSize: 9, fontWeight: "bold" }}>United African Grain</Text>
                                                                </View>

                                                                {/* Inner rectangle */}
                                                                <View style={{ width: "75%", height: 35, border: "2px solid black", padding: 1 }}>
                                                                    <Text style={{ fontSize: 9, fontWeight: "bold", textAlign: "center" }}>{pdf_data?.delivery_note?.delivery_date_time ? moment(page_data?.delivery_note?.delivery_date_time).format("Do MMMM , YYYY") : "---"}</Text>
                                                                    <Text style={{ fontSize: 9, fontWeight: "bold", textAlign: "center" }}>{pdf_data?.delivery_note?.delivery_date_time ? moment(page_data?.delivery_note?.delivery_date_time).format("h:mm:ss") : "---"}</Text>

                                                                </View>
                                                                <View style={{ height: 15 }}>
                                                                    <Text style={{ fontSize: 9, fontWeight: "bold", textAlign: "center" }}>{pdf_data?.delivery_note?.user_delivery_confirmer ? pdf_data?.delivery_note?.user_delivery_confirmer : "---"}</Text>
                                                                </View>
                                                            </View>
                                                        </>
                                                    )
                                                }
                                            </View>
                                            <View style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "32%", padding: 2, borderRight: "2px solid #A9A9A9" }}>
                                                <View style={{ fontSize: 12 }}>
                                                    <Text style={{ textAlign: "center", textTransform: "uppercase", fontSize: 12, fontWeight: "bold", padding: 1, borderBottom: "1px solid #A9A9A9" }}>Goods Received  in Good Order</Text>
                                                </View>
                                                <View style={{ display: "flex", flexDirection: "row", padding: 2 }}>
                                                    <View style={{ paddingLeft: 5 }}>
                                                        <Text style={{ textAlign: "center", textTransform: "uppercase", fontSize: 8, fontWeight: "bold", padding: 1 }}>Please Indicate any Delivery Shortage or Discrepancy</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", borderRadius: "1px" }}>

                                        </View>
                                    </View>
                                </View>

                            </View>
                        </Page>
                    </Document>
                </>
            )
        }
        </>
    );
};

export default DeliveryNotePDF;